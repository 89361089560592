import { DB_SETTINGS_ITEMS } from 'interface/database'
import { localStorageSetting } from 'local-store/setting'
import { put } from 'redux-saga/effects'
import { actionCreators } from 'store/slices/setting'

import { updateSetting } from '../requests/database/update-setting'

type WhatYouYield = Promise<void> | { payload: any; type: string }
type WhatYouReturn = any

// what you accept as a return from requestCopy
type WhatYouAccept = undefined

export function* handleSetting(action: {
  payload: { uid: string | null; dbRef: DB_SETTINGS_ITEMS; data: unknown }
}): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
  try {
    const { uid, dbRef, data } = action.payload

    // update local storage
    localStorageSetting(dbRef, data)

    // if not logged in user, directly update redux
    if (!uid) {
      yield put(actionCreators[dbRef](data))
    }

    // if logged in user, first update database, and let redux store to be updated in with the subscriber set with firebase in web\src\utils\database\load-setting.ts:49
    if (uid) {
      yield updateSetting(uid, dbRef, data)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export {}
