/**
 * Create a new array and howMany argument is ensure how many element in the array.
 * @param howMany
 * @returns
 */
export const arrayIterator = (howMany: number, startFrom = 0) => {
  // eslint-disable-next-line prefer-spread
  return Array.apply(null, Array(howMany)).map(function (x, i) {
    return i + startFrom
  })
}
