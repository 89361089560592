import { useEventListener } from 'hooks/use-event-listener'
import { createContext, ReactNode, useContext, useState } from 'react'
import toast from 'react-hot-toast'
import { isDbConnectedPage } from 'utils/is-db-connected-page'

type Offline = boolean

const initialState: Offline = false

const OfflineContext = createContext(initialState)

export const OfflineProvider = ({ children }: { children: ReactNode }) => {
  const [isOffline, setIsOffline] = useState(false)

  useEventListener({
    eventType: 'online',
    listener: () => {
      // back online from offline
      if (isOffline) {
        if (isDbConnectedPage()) {
          toast.success('Back online! Reconnecting...')

          setTimeout(() => {
            window.location.reload()
          }, 1500)
        } else {
          toast.success('Back online!')
        }
      }

      setIsOffline(false)
    },
    wait: 0,
  })

  useEventListener({
    eventType: 'offline',
    listener: () => {
      setIsOffline(true)
      toast.error('No internet!')
    },
    wait: 0,
  })

  return <OfflineContext.Provider value={isOffline}>{children}</OfflineContext.Provider>
}

export const useOffline = () => useContext(OfflineContext)
