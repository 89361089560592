import { AiMessage } from 'store/slices/ai/ai'

export const getCurtailedMessages = (aiMessages: AiMessage[], maxLength: number): AiMessage[] => {
  let totalLength = 0

  const curtailedAiMessages = aiMessages
    .slice()
    .reverse()
    .reduce((result, message) => {
      const remainingLength = maxLength - totalLength

      if (remainingLength <= 0) {
        return result
      }

      if (message.content.length <= remainingLength) {
        totalLength += message.content.length
        result.unshift(message)
      } else {
        const curtailedContent = message.content.slice(-remainingLength)
        result.unshift({ ...message, content: curtailedContent })
        totalLength = maxLength
      }

      return result
    }, [] as AiMessage[])

  console.log({
    curtailedAiMessages: curtailedAiMessages.reduce((prev, curr) => prev + curr.content.length, 0),
    original: aiMessages.reduce((prev, curr) => prev + curr.content.length, 0),
  })

  return curtailedAiMessages
}
