import { ActionCreatorWithPayload, createSlice } from '@reduxjs/toolkit'
import { PaletteMode } from 'components/app-theme-provider/interface'
import { MS_IN_MINUTE } from 'constants/calculations'
import { SETTINGS_DEFAULT } from 'constants/setting'
import dayjs from 'dayjs'
import { DB_SETTINGS_ITEMS, DbSettings } from 'interface/database'
import type { RootState } from 'store'

// Define a type for the slice state
export type SettingProps = DbSettings & {
  initialized: boolean
  loading: boolean
}

// Define the initial state using that type
const initialState: SettingProps = Object.freeze({
  initialized: false,
  loading: false,
  paletteMode: null,
  adResumeTimestamp: (SETTINGS_DEFAULT.adResumeTimestamp || 0) as number,
  showLiveStat: !!SETTINGS_DEFAULT.showLiveStat,
  showLiveWordPerf: !!SETTINGS_DEFAULT.showLiveWordPerf,
  showProgressBar: !!SETTINGS_DEFAULT.showProgressBar,
  showTypingHands: !!SETTINGS_DEFAULT.showTypingHands,
  testDuration: MS_IN_MINUTE,
  firstName: (SETTINGS_DEFAULT.firstName || '') as string,
  lastName: (SETTINGS_DEFAULT.lastName || '') as string,
  userName: (SETTINGS_DEFAULT.userName || '') as string,
  email: (SETTINGS_DEFAULT.email || '') as string,
  address: (SETTINGS_DEFAULT.address || '') as string,
  dateOfBirth: (SETTINGS_DEFAULT.dateOfBirth || 0) as number,
})

const settingSlice = createSlice({
  name: 'setting',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPaletteMode: (state: SettingProps, { payload }: { payload: PaletteMode }) => ({
      ...state,
      paletteMode: payload,
    }),

    __setLoading: (state: SettingProps, { payload }: { payload: boolean }) => {
      state.loading = payload
    },

    setAdResumeTimestampUntilPageReloadOr30Minutes: (state: SettingProps) => {
      // let users hide ads until page reload or maximum for 30 minutes
      state.adResumeTimestamp = dayjs().add(30, 'minutes').valueOf()
    },

    __setAdResumeTimestamp: (state: SettingProps, { payload }: { payload: number }) => {
      state.adResumeTimestamp = payload === null ? initialState.adResumeTimestamp : payload
    },

    setAdResumeTimestamp: (
      _state: SettingProps,
      _action: { payload: { uid: string | null; dbRef: DB_SETTINGS_ITEMS; data: unknown } }
    ) => {
      // saga watcher. No need to add anything here but the above arg types are required for the saga to pick the typings
    },

    __setShowLiveStat: (state: SettingProps, { payload }: { payload: boolean }) => {
      state.showLiveStat = payload === null ? initialState.showLiveStat : payload
    },

    setShowLiveStat: (
      _state: SettingProps,
      _action: { payload: { uid: string | null; dbRef: DB_SETTINGS_ITEMS; data: unknown } }
    ) => {
      // saga watcher. No need to add anything here but the above arg types are required for the saga to pick the typings
    },

    __setShowLiveWordPerf: (state: SettingProps, { payload }: { payload: boolean }) => {
      state.showLiveWordPerf = payload === null ? initialState.showLiveWordPerf : payload
    },

    setShowLiveWordPerf: (
      _state: SettingProps,
      _action: { payload: { uid: string | null; dbRef: DB_SETTINGS_ITEMS; data: unknown } }
    ) => {
      // saga watcher. No need to add anything here but the above arg types are required for the saga to pick the typings
    },

    __setShowProgressBar: (state: SettingProps, { payload }: { payload: boolean }) => {
      state.showProgressBar = payload === null ? initialState.showProgressBar : payload
    },

    setShowProgressBar: (
      _state: SettingProps,
      _action: { payload: { uid: string | null; dbRef: DB_SETTINGS_ITEMS; data: unknown } }
    ) => {
      // saga watcher. No need to add anything here but the above arg types are required for the saga to pick the typings
    },

    __setShowTypingHands: (state: SettingProps, { payload }: { payload: boolean }) => {
      state.showTypingHands = payload === null ? initialState.showTypingHands : payload
    },

    setShowTypingHands: (
      _state: SettingProps,
      _action: { payload: { uid: string | null; dbRef: DB_SETTINGS_ITEMS; data: unknown } }
    ) => {
      // saga watcher. No need to add anything here but the above arg types are required for the saga to pick the typings
    },

    /**
     * add each item on the actions list, actionCreators list, on the reducers, on the root-saga, on the `enum DB_REF`, and on SETTINGS_DEFAULT list
     */

    __setTestDuration: (state: SettingProps, { payload }: { payload: number }) => {
      state.testDuration = payload || MS_IN_MINUTE
    },

    setTestDuration: (
      _state: SettingProps,
      _action: { payload: { uid: string | null; dbRef: DB_SETTINGS_ITEMS; data: unknown } }
    ) => {
      // saga watcher. No need to add anything here but the above arg types are required for the saga to pick the typings
    },

    __setFirstName: (state: SettingProps, { payload }: { payload: string }) => {
      state.firstName = payload || ''
    },

    setFirstName: (
      _state: SettingProps,
      _action: { payload: { uid: string | null; dbRef: DB_SETTINGS_ITEMS; data: unknown } }
    ) => {
      // saga watcher. No need to add anything here but the above arg types are required for the saga to pick the typings
    },

    __setLastName: (state: SettingProps, { payload }: { payload: string }) => {
      state.lastName = payload || ''
    },

    setLastName: (
      _state: SettingProps,
      _action: { payload: { uid: string | null; dbRef: DB_SETTINGS_ITEMS; data: unknown } }
    ) => {
      // saga watcher. No need to add anything here but the above arg types are required for the saga to pick the typings
    },

    __setUserName: (state: SettingProps, { payload }: { payload: string }) => {
      state.userName = payload || ''
    },

    setUserName: (
      _state: SettingProps,
      _action: { payload: { uid: string | null; dbRef: DB_SETTINGS_ITEMS; data: unknown } }
    ) => {
      // saga watcher. No need to add anything here but the above arg types are required for the saga to pick the typings
    },

    __setEmail: (state: SettingProps, { payload }: { payload: string }) => {
      state.email = payload || ''
    },

    setEmail: (
      _state: SettingProps,
      _action: { payload: { uid: string | null; dbRef: DB_SETTINGS_ITEMS; data: unknown } }
    ) => {
      // saga watcher. No need to add anything here but the above arg types are required for the saga to pick the typings
    },

    __setAddress: (state: SettingProps, { payload }: { payload: string }) => {
      state.address = payload || ''
    },

    setAddress: (
      _state: SettingProps,
      _action: { payload: { uid: string | null; dbRef: DB_SETTINGS_ITEMS; data: unknown } }
    ) => {
      // saga watcher. No need to add anything here but the above arg types are required for the saga to pick the typings
    },

    __setDateOfBirth: (state: SettingProps, { payload }: { payload: number }) => {
      state.dateOfBirth = payload || 0
    },

    setDateOfBirth: (
      _state: SettingProps,
      _action: { payload: { uid: string | null; dbRef: DB_SETTINGS_ITEMS; data: unknown } }
    ) => {
      // saga watcher. No need to add anything here but the above arg types are required for the saga to pick the typings
    },
  },
})

export const {
  setPaletteMode,
  setAdResumeTimestampUntilPageReloadOr30Minutes,

  // add each item on the slice actions list, actionCreators list, on the reducers, on the root-saga, on the `enum DB_REF`, and on SETTINGS_DEFAULT list
  setAdResumeTimestamp,
  __setAdResumeTimestamp,
  setShowLiveStat,
  __setShowLiveStat,
  setShowLiveWordPerf,
  __setShowLiveWordPerf,
  setShowProgressBar,
  __setShowProgressBar,
  setShowTypingHands,
  __setShowTypingHands,
  setTestDuration,
  __setTestDuration,
  setFirstName,
  __setFirstName,
  setLastName,
  __setLastName,
  setUserName,
  __setUserName,
  setEmail,
  __setEmail,
  setAddress,
  __setAddress,
  setDateOfBirth,
  __setDateOfBirth,
} = settingSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectApp = (state: RootState) => state.setting

export const actionCreators: Record<
  DB_SETTINGS_ITEMS,
  // Typescript 'any' is okay to be used here since we are looping through all the action creators of Settings
  ActionCreatorWithPayload<any>
> = {
  paletteMode: setPaletteMode,

  // add each item on the slice actions list, actionCreators list, on the reducers, on the root-saga, on the `enum DB_REF`, and on SETTINGS_DEFAULT list
  adResumeTimestamp: __setAdResumeTimestamp,
  showLiveStat: __setShowLiveStat,
  showLiveWordPerf: __setShowLiveWordPerf,
  showProgressBar: __setShowProgressBar,
  showTypingHands: __setShowTypingHands,
  testDuration: __setTestDuration,
  firstName: __setFirstName,
  lastName: __setLastName,
  userName: __setUserName,
  email: __setEmail,
  address: __setAddress,
  dateOfBirth: __setDateOfBirth,
}

export { settingSlice }
