import { PgProps } from '../pgSlice'

/**
 * - pass state before defining newActiveIndex
 * @param state
 * @returns
 */
export const getLastStrokeTimestamp = (state: PgProps) => {
  const latest = state.strokes?.[state.activeIndex]?.startTimestamp || -Infinity

  return latest === -Infinity ? NaN : latest
}
