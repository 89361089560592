export function sanitizeFirebaseKey<T>(key: T): T {
  if (typeof key !== 'string' || key.length === 0) {
    throw new Error('Key must be a non-empty string')
  }

  const replacer = (match: string): string => {
    const code = match.charCodeAt(0).toString(16).toUpperCase()
    return `%${code}`
  }

  // eslint-disable-next-line no-useless-escape
  return key.replace(/[\.\#\$\[\]\/]/g, replacer) as T
}
