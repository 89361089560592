import { css, FlattenSimpleInterpolation } from 'styled-components'

import { BreakpointQueries, BreakpointSpecifications, DefaultAppTheme } from '../interface'

const DEFAULT_FONT_FAMILY =
  '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
const DEFAULT_FONT_SIZE_16 = '1rem'
const DEFAULT_FONT_STYLE = 'normal'
const DEFAULT_LINE_HEIGHT = 1.5
const FONT_WEIGHT_400 = 400
const FONT_WEIGHT_BOLD = 700
const FONT_WEIGHT_900 = 900

export const breakpoints: BreakpointSpecifications = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: { xs: 0, lg: 1280, sm: 600, xl: 1920, md: 960 },
  get down() {
    return this.keys
      .map((key) => ({
        [key]: `@media (max-width: ${this.values[key] - 1}px)`,
      }))
      .reduce((prev, curr) => ({ ...prev, ...curr }), {}) as BreakpointQueries
  },
  get up() {
    return this.keys
      .map((key) => ({
        [key]: `@media (min-width: ${this.values[key]}px)`,
      }))
      .reduce((prev, curr) => ({ ...prev, ...curr }), {}) as BreakpointQueries
  },
}

export const defaultAppTheme: DefaultAppTheme = {
  typography: {
    fontSize: DEFAULT_FONT_SIZE_16,
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeightRegular: FONT_WEIGHT_400,
    fontWeightBold: FONT_WEIGHT_BOLD,
    fontWeightBlack: FONT_WEIGHT_900,
    heading1: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: DEFAULT_FONT_STYLE,
      fontWeight: FONT_WEIGHT_BOLD,
      fontSize: '2.25rem',
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
    heading2: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: DEFAULT_FONT_STYLE,
      fontWeight: FONT_WEIGHT_400,
      fontSize: '1.125rem',
      lineHeight: 1.6,
    },
    heading3: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: DEFAULT_FONT_STYLE,
      fontWeight: FONT_WEIGHT_400,
      fontSize: '1.375rem',
      lineHeight: 1.6,
    },
    heading4: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: DEFAULT_FONT_STYLE,
      fontWeight: FONT_WEIGHT_400,
      fontSize: '1.5rem',
      lineHeight: 1.6,
    },
    body1: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: DEFAULT_FONT_STYLE,
      fontWeight: FONT_WEIGHT_400,
      fontSize: '1rem',
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
    body2: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: DEFAULT_FONT_STYLE,
      fontWeight: FONT_WEIGHT_400,
      fontSize: '1.25rem',
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
    smallText: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: DEFAULT_FONT_STYLE,
      fontWeight: FONT_WEIGHT_400,
      fontSize: '0.625rem',
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
    smallText2: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: DEFAULT_FONT_STYLE,
      fontWeight: FONT_WEIGHT_400,
      fontSize: '0.875rem',
      lineHeight: DEFAULT_LINE_HEIGHT,
    },

    button: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: DEFAULT_FONT_STYLE,
      fontWeight: FONT_WEIGHT_BOLD,
      fontSize: '0.875rem',
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
  },
  spacing: function (...rest: number[]): FlattenSimpleInterpolation {
    const spaces = rest.reduce((spacer: string, unit: number) => `${spacer} ${unit * 0.5}rem`, ``)
    return css`
      ${spaces};
    `
  },
  breakpoints,
  borderRadius: {
    0: '0',
    2: '2px',
    3: '4px',
    5: '1rem',
    rounded: '99rem',
  },
  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
}
