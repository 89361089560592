import { DB_REF, RESEARCH_DB_REF } from 'interface/database'
import { assertProd } from 'utils/assert-prod'
import { urlCleanDuplicateSlash } from 'utils/url-clean-duplicate-slash'

interface GetUserRefOptions {
  dbRef?: DB_REF
  firstNestedRef?: string
}

export const getUserRef = (uid: string, options: GetUserRefOptions): string => {
  const { dbRef, firstNestedRef } = options

  const userRef = `/users/${uid}/`

  if (!dbRef) {
    return urlCleanDuplicateSlash(userRef)
  }

  if (!firstNestedRef) {
    return urlCleanDuplicateSlash(`${userRef}/${dbRef}/`)
  }

  return urlCleanDuplicateSlash(`${userRef}/${dbRef}/${firstNestedRef}/`)
}

interface GetAiResearchRefOptions {
  chatLogsDbRef: RESEARCH_DB_REF.aiChatLogs
  threadStartTimestamp: string
}

export const getAiChatLogRef = (uid: string, options: GetAiResearchRefOptions): string => {
  const { threadStartTimestamp, chatLogsDbRef } = options

  assertProd(!!uid, 'uid is required. (getAiChatLogRef)')
  assertProd(!!threadStartTimestamp, 'threadStartTimestamp is required. (getAiChatLogRef)')

  return `/research/${chatLogsDbRef}/${uid}/${threadStartTimestamp}/`
}

export const getAiBetaSubscribersRef = (): string => {
  return `/subscribers/aiBetaSubscribers/`
}
