/**
 * enum PG os the list of names of the playgrounds. Based on the PG name, the redux state is created and udpated.
 * For now we only have main-pg in use.
 */
export enum PG {
  MAIN_PG = 'main-pg',
  NUMBER_PRACTICE_PG = 'number-practice-pg',
}

export const VALID_CHARS =
  '`1234567890-=qwertyuiop[]asdfghjkl;\'\\zxcvbnm,./~!@#$%^&*()_+QWERTYUIOP{}ASDFGHJKL:"|ZXCVBNM<>? '.split('')

export const OUT_OF_SCREEN_TEXT_OFFSET = 150

export const PG_BORDER_RADIUS = 5
