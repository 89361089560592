// Credit:https://github.com/donavon/use-persisted-state/blob/develop/src/usePersistedState.js

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEventListener } from 'hooks/use-event-listener'
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import createGlobalState from './create-global-state'

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

const usePersistedState = (initialState, key, { get, set }) => {
  const globalState = useRef(null)
  const [state, setState] = useState(() => get(key, initialState))

  // subscribe to `storage` change events
  useEventListener({
    eventType: 'storage',
    wait: 0,
    listener: ({ key: k, newValue }) => {
      if (k === key) {
        const newState = JSON.parse(newValue)
        if (state !== newState) {
          setState(newState)
        }
      }
    },
  })

  // only called on mount
  useIsomorphicLayoutEffect(() => {
    // register a listener that calls `setState` when another instance emits
    globalState.current = createGlobalState(key, setState, initialState)

    return () => {
      globalState.current.deregister()
    }
  }, [initialState, key])

  const persistentSetState = useCallback(
    (newState) => {
      //   if (!globalState.current) {
      //     // register a listener that calls `setState` when another instance emits
      //     globalState.current = createGlobalState(key, setState, initialState)
      //   }

      const newStateValue = typeof newState === 'function' ? newState(state) : newState

      // persist to localStorage
      set(key, newStateValue)

      setState(newStateValue)

      // inform all of the other instances in this tab
      globalState.current.emit(newState)
    },
    [state, set, key]
  )

  return [state, persistentSetState]
}

export default usePersistedState
