import { MS_IN_DAY } from 'constants/calculations'
import { LESSON_NAME, LESSON_NAMES } from 'constants/lessons'
import { RESPONSE_COPY } from 'interface/api/copy'
import store from 'store2'
import { assert } from 'utils/assert'

export const hasCopy = (lessonName: LESSON_NAME) => {
  return !!store(lessonName)?.length
}

export const setCopies = (lessonName: LESSON_NAME, copies: RESPONSE_COPY[]) => {
  store(lessonName, copies)
}

/**
 * This util extracts a copy from the localstorage and stores the rest of the copies.
 * For example, there are 5 copies available, after execution of this util
 * function there will be 4 copies remaining.
 * @param lessonName e.g. paragraph, commonWords, duos, trios, etc.
 * @returns one copy from the local storage
 */
export const extractCopy = (lessonName: LESSON_NAME): null | RESPONSE_COPY[] => {
  const localCopies: null | RESPONSE_COPY[] = store(lessonName)

  if (!localCopies) return null
  if (!Array.isArray(localCopies)) return null
  if (localCopies.length < 1) return null

  assert(
    Array.isArray(localCopies) && localCopies.length > 0,
    'local copies in the localstorage must be null or have at least one array item'
  )

  const extracted = localCopies[0]
  const remainingCopies = localCopies.slice(1)

  setCopies(lessonName, remainingCopies)

  return [extracted]
}

export const getCopy = (lessonName: LESSON_NAME): null | RESPONSE_COPY[] => {
  const localCopy: null | RESPONSE_COPY[] = store(lessonName)

  if (!localCopy) return null
  if (!Array.isArray(localCopy)) return null
  if (localCopy.length < 1) return null

  assert(
    Array.isArray(localCopy) && localCopy.length > 0,
    'local copies in the localstorage must be null or have at least one array item'
  )

  return localCopy
}

// cleanup the locally saved lessons copies every 7 days to make sure if any changes are brought
// then the users get the new lessons copies. How it works, if we clean the local copies, then the
// all will fetch the latest, otherwise app uses the lesson copies from local storage to reduce server request.
export const scheduledCleanup = () => {
  const CLEAN = 'CLEAN'
  const lastClean = store(CLEAN)

  // new user or device
  if (!lastClean) {
    store(CLEAN, new Date().valueOf())
    return
  }

  const cleanedDaysAgo = (new Date().valueOf() - lastClean) / MS_IN_DAY

  // if less than 7 days, no need to cleanup and return immediately
  if (cleanedDaysAgo < 7) return

  // clean all stored lessons
  for (const name in LESSON_NAMES) {
    if (Object.prototype.hasOwnProperty.call(LESSON_NAMES, name)) {
      store.remove(name)
    }
  }

  // set new cleanup timestamp
  store(CLEAN, new Date().valueOf())
}
