import { Palette, PaletteMode } from '../interface'
import {
  gray,
  gray3,
  grayB,
  grayC,
  grayWhitish,
  green1,
  green2,
  lightGray,
  red1,
  red2,
  teal,
  tealLight,
  white,
} from './colors'

export const paletteLight: Palette = {
  mode: PaletteMode.light,
  background: {
    default: grayWhitish,
    paper: white,
  },
  text: {
    primary: gray3,
    secondary: white,
    selection: teal,
    error: red1,
  },
  primary: {
    main: teal,
    contrastText: white,
    light: tealLight,
  },
  success: {
    main: green1,
    light: green2,
    contrastText: gray3,
  },
  error: {
    main: red1,
    light: red2,
    contrastText: gray3,
    errorPageColor: gray,
  },
  border: {
    light: lightGray,
    main: gray,
  },
  shadow: {
    main: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  disabled: {
    main: lightGray,
    contrastText: grayB,
    border: grayC,
  },
  link: {
    main: teal,
  },
}
