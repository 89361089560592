import { IS_DEV } from 'constants/app'

// TODO: improve this utils handle more cases. For now, just removing https://typingmentor.com
export const relativeUrl = (url: string) => {
  const key = IS_DEV ? 'localhost:3000' : 'typingmentor.com'

  const num = url.indexOf(key)

  if (num === -1) return url

  if (url.slice(num + key.length) === '') return '/'

  return url.slice(num + key.length)
}
