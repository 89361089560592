import { useTheme as useNextThemes } from 'next-themes'
import React from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { updatePalette } from 'utils/database/update-settings'

import { GlobalStyle } from './AppThemeProvider.styles'
import { defaultAppTheme, paletteDark, paletteLight } from './config'
import { Palette, PaletteMode, TogglePaletteMode } from './interface'

// https://github.com/styled-components/styled-components/issues/3738#issuecomment-1157747943
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GlobalStyleProxy: any = GlobalStyle
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ThemeProviderProxy: any = ThemeProvider

interface ThemeProviderProps {
  children: React.ReactNode
}

const Main = ({ children }: ThemeProviderProps) => {
  // use useNextThemes from 'next-themes' only for the sake of toggling, setting, and injecting the script
  const paletteProps = useNextThemes()
  const colorMode = paletteProps.resolvedTheme

  // HIGHLIGHT: if you toggle the color mode manually by clicking the button, it
  // will not work properly on the next page reload if dark is selected because
  // on the page reload, we are not sending cookies to the server to be able to
  // detect the selected color mode. So, we are not letting users to toggle dark-mode
  // manually. But system preferred color mode loads as expected.
  const togglePalette: TogglePaletteMode = (mode: PaletteMode, uid?: string | null) => {
    paletteProps.setTheme(mode)
    // update firebase if uid is available
    if (uid) updatePalette(uid, mode)
  }

  const palette: Palette = colorMode === 'dark' ? paletteDark : paletteLight

  // const mounted = useMountedState()

  // if (!mounted) {
  //   return null
  // }

  const appTheme: DefaultTheme = {
    ...defaultAppTheme,
    palette,
    togglePalette,
  }

  return (
    <ThemeProviderProxy theme={appTheme}>
      <div>
        <GlobalStyleProxy />
        {children}
      </div>
    </ThemeProviderProxy>
  )
}

export default React.memo(Main)
