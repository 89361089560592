import { API } from 'constants/api-routes'
import { PARAM } from 'constants/copy'
import { LESSON_NAME } from 'constants/lessons'
import { RESPONSE_COPY } from 'interface/api/copy'

export const requestCopy = async (lessonName: LESSON_NAME): Promise<RESPONSE_COPY[]> => {
  try {
    const response = await fetch(`${API.COPY}?${PARAM.name}=${lessonName}`, {
      method: 'GET',
    })
    const copy = await response.json()

    return copy as RESPONSE_COPY[]
  } catch (error) {
    throw new Error(`"${API.COPY}" copy fetch failed.`)
  }
}

const HOW_MANY_FETCH_IN_ADVANCE = 5

export const requestManyParagraph = async (): Promise<RESPONSE_COPY[]> => {
  try {
    const response = await fetch(`${API.MANY_PARAGRAPHS}?${PARAM.howMany}=${HOW_MANY_FETCH_IN_ADVANCE}`, {
      method: 'GET',
    })
    const paragraphs = await response.json()

    return paragraphs
  } catch (error) {
    throw new Error(`"${API.MANY_PARAGRAPHS}" copy fetch failed.`)
  }
}

export {}
