import { MS_IN_MINUTE } from 'constants/calculations'
import { LESSON_DURATION, LESSON_NAME } from 'constants/lessons'
import { PG } from 'constants/pg'
import type { RootState } from 'store'
import { wpmToMsPerCharacter } from 'utils/calculator/wpm-to-ms-per-character'

import { createGenericSlice } from '../utils/createGenericSlice'
import { GetChunksReturn, GetWordDetailsReturn, PayloadHandleRestart, Rules, Strokes, TypeCharacter } from './interface'
import { addStrokeSlot } from './utils/add-stroke-slot'
import { getActiveIndex } from './utils/getActiveIndex'
import { getActiveWordCharacterDetails } from './utils/getActiveWordCharacterDetails'
import { getChunks } from './utils/getChunks'
import { getLastStrokeTimestamp } from './utils/getLastStrokeTimestamp'
import { getTotalPauseDuration } from './utils/getTotalPauseDuration'
import { getWordDetails } from './utils/getWordDetails'
import { handlePause } from './utils/handlePause'
import { tooManyConsecutiveWrong } from './utils/too-many-consecutive-wrong'

// Define a type for the slice state
export type PgProps = {
  rules: Rules
  duration: number
  lessonName: LESSON_NAME
  copyLoading: boolean
  started: null | number
  finished: null | number
  inProgress: boolean
  text: string
  chunks: GetChunksReturn
  firstChunkWordDetails: GetWordDetailsReturn[]
  wpm: number
  wpmCalculatedAt: number
  accuracy: number
  timeElapsed: number
  strokes: Strokes
  activeIndex: number
  pauseTimeout: number
  // pauseHistory is an object of { "pause timestamp": "duration of pause from the timestamp" }
  pauseHistory: Record<string, number>
  activeWordCharacterDetails: TypeCharacter[]
  // any valid key stroke to attempt to type something is counted as one hit. Backspace is considered a hit.
  hits: number
}

const getInitialState: (rules: Rules) => PgProps = (rules) => {
  const defaultActiveIndex = 0

  const initialChunks = getChunks({
    text: rules.text,
    activeIndex: defaultActiveIndex,
  })

  return {
    rules,
    text: rules.text,
    duration: rules.duration,
    lessonName: rules.lessonName,
    copyLoading: true,
    started: null,
    finished: null,
    inProgress: false,
    chunks: initialChunks,
    firstChunkWordDetails: [],
    wpm: 0,
    wpmCalculatedAt: 0,
    accuracy: 0,
    timeElapsed: 0,
    strokes: {},
    hits: 0,
    activeIndex: defaultActiveIndex,
    pauseTimeout: 3000,
    pauseHistory: {},
    activeWordCharacterDetails: getActiveWordCharacterDetails({
      activeWord: initialChunks.active,
      activeWordStartIndex: initialChunks.activeWordStartIndex,
      strokes: [],
    }),
  }
}

const initialState: PgProps = getInitialState({
  lessonName: 'PARAGRAPH',
  text: '',
  duration: 0,
})

const getSlice = (name: PG) =>
  createGenericSlice({
    name,
    initialState,

    reducers: {
      // if set rules, don't change the active test if it started already. Just update the rules object, and before starting the next test, the new rules will be applied.
      setRules: (state: PgProps, { payload }: { payload: Partial<Rules> }) => {
        const updated = { ...state.rules }

        ;(Object.keys(payload) as Array<keyof Rules>).forEach((ruleItem) => {
          updated[ruleItem] = payload[ruleItem] as never
        })

        // reset with new rules and return new state immediately
        if (!state.started) return getInitialState(updated)
        // set new rules for next test
        else state.rules = updated
      },

      // whenever you invoke handleRestart() also invoke setLocalStoreLessonRules({...}) if you want to persist this rules for that specific route e.g. /typing-practice/
      handleRestart: (
        state: PgProps,
        {
          payload: {
            pgName,
            rules: { lessonName, duration },
          },
        }: { payload: PayloadHandleRestart }
      ) => {
        if (lessonName) {
          state.rules.lessonName = lessonName
          state.rules.duration = duration === undefined ? LESSON_DURATION[lessonName] : duration
        }
      },

      _restart: (state: PgProps) => {
        return getInitialState(state.rules)
      },

      setCopyLoading: (state: PgProps, { payload }: { payload: boolean }) => {
        state.copyLoading = payload
      },

      fetchInAdvance: () => {
        // this function is for saga watcher
      },

      addMoreText: (state: PgProps) => {
        state.text += ` ${state.rules.text}`
      },

      autoPause: (state: PgProps) => {
        if (state.finished) return

        const typingStartTimestamp = state.strokes[0]?.startTimestamp

        if (!typingStartTimestamp) return

        const now = new Date().valueOf()

        // following function call updates the state.
        // call handle-pause to update state for pause history and inProgress
        handlePause({ state, now, userTypedSomething: false })
      },

      // calcWpm that doesn't trigger on user interaction rather it runs at a fixed interval
      calcWpm: (state: PgProps) => {
        const typingStartTimestamp = state.strokes[0]?.startTimestamp

        if (!typingStartTimestamp) return

        const now = new Date().valueOf()

        // getTotalPauseDuration() should be used after this^ handlePause() call since handlePause() updates the pause history
        const timeElapsed = now - typingStartTimestamp - getTotalPauseDuration(state)

        const numberOfCorrectlyTypedChars = Object.values(state.strokes).filter((el) => el.correctlyTyped).length

        const eachCharacterMs = timeElapsed / numberOfCorrectlyTypedChars

        const characterPerMinute = MS_IN_MINUTE / eachCharacterMs

        state.timeElapsed = timeElapsed
        // let assume 5 character makes a word on an avg
        const calculatedWpm = timeElapsed === 0 ? 0 : characterPerMinute / 5
        // round to 2 decimal places
        state.wpm = Math.round(calculatedWpm * 100) / 100

        const calculatedAccuracy = numberOfCorrectlyTypedChars / state.hits
        // round to 2 decimal places
        state.accuracy = Math.round(calculatedAccuracy * 100) / 100
        state.wpmCalculatedAt = now
      },

      handleCopyHolderInput: (
        state: PgProps,
        {
          payload: { key: actuallyTypedCharacter },
        }: {
          payload: { key: string }
        }
      ) => {
        // declare "now" at top, and use onwards to avoid small ms miss-match of new Date().value() timestamps
        const now = new Date().valueOf()

        const prevStateInProgress = state.inProgress

        const resumedAfterLastStrokeTimestamp = !prevStateInProgress ? getLastStrokeTimestamp(state) : undefined

        const typedIndex = state.activeIndex
        const newActiveIndex = getActiveIndex(typedIndex, actuallyTypedCharacter)

        // disallow backspace if reached first character
        if (newActiveIndex < 0) {
          return
        }

        const hitBackspace = newActiveIndex === typedIndex - 1

        // place this check before adding 1 to the state.activeIndex
        if (
          !hitBackspace &&
          tooManyConsecutiveWrong({
            strokes: state.strokes,
            typedIndex,
            actuallyTypedCharacter,
          })
        ) {
          return
        }

        // HIGHLIGHT: in this function, most code should go after this line, since the previous lines of this function is just to check if userTyped something.

        state.activeIndex = newActiveIndex

        if (!state.started) {
          state.started = now
        }

        state.inProgress = true

        const newChunks = getChunks({
          text: state.text,
          activeIndex: state.activeIndex,
        })
        state.chunks = newChunks

        // update useInputHistory for when active index got changed
        if (state.activeIndex !== typedIndex) {
          // active index went forward
          if (state.activeIndex === typedIndex + 1) {
            if (!state.strokes[typedIndex]) {
              // HIGHLIGHT: register input for 0 index actually being done here. because it was not possible to detect when user started typing. we detect it when user hits for first time and we update+register altogether for index 0.
              if (typedIndex === 0) {
                addStrokeSlot({
                  indexToBeAdded: 0,
                  now,
                  state,
                })
              } else {
                throw new Error(
                  `updateStroke should not be called for an index ${typedIndex} that is yet not registered through registerInput().`
                )
              }
            }

            // assign the stroke object after addStrokeSlot() call above to make sure we get the latest stroke state
            const stroke = state.strokes[typedIndex]

            // remove previous startTimestamp and update newly in the case when user paused on a character and resumes typing.
            if (!prevStateInProgress && typedIndex > 0) {
              stroke.startTimestamp = now - wpmToMsPerCharacter(state.wpm)
            }

            stroke.endTimestamp = now
            stroke.actuallyTypedCharacter = actuallyTypedCharacter
            stroke.correctlyTyped = stroke.characterToBeTyped === actuallyTypedCharacter
            stroke.duration = stroke.endTimestamp - stroke.startTimestamp
            stroke.attempts = [...stroke.attempts, { timestamp: now, typedChar: actuallyTypedCharacter }]
          }

          // not backspace
          if (!hitBackspace) {
            state.hits++
          }

          // register input
          addStrokeSlot({
            indexToBeAdded: state.activeIndex,
            now,
            state,
          })

          // handle-pause to update state for pause history and inProgress
          handlePause({
            state,
            now,
            userTypedSomething: true,
            resumedAfterLastStrokeTimestamp,
          })
        }

        const { firstChunkWords, active, activeWordStartIndex } = newChunks

        const firstChunkWordDetails: GetWordDetailsReturn[] = firstChunkWords.map(
          ({ word, indexOfWordsFirstCharacter }) => {
            return getWordDetails({
              word,
              strokesInWord: word.split('').map((_, index) => {
                const stroke = state.strokes[index + indexOfWordsFirstCharacter]
                return stroke
              }),
            })
          }
        )

        state.firstChunkWordDetails = firstChunkWordDetails

        state.activeWordCharacterDetails = getActiveWordCharacterDetails({
          activeWord: active,
          activeWordStartIndex,
          // could not fix the following "any" because state.strokes is not being treated as an array by Immer inside this reducer although it is an array.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          strokes: state.strokes as any,
        })

        // IIFE for handling typing finished
        ;(() => {
          const timesUp = () => {
            if (!state.started) return false
            if (!state.duration) return false

            const timeSpent = now - (state.started + getTotalPauseDuration(state))

            return timeSpent >= state.duration
          }

          // finished
          if (state.activeIndex === state.text.length || timesUp()) {
            state.finished = now
            state.inProgress = false
          }
        })()

        // console.timeEnd('pg-slice')
      },

      saveResult: (
        // need to keep this unused args for saga watchers to know what is the type of the payload.
        _state: PgProps,
        _action: {
          payload: {
            id: string
            pgName: PG
            // stat: DbLessonStat
            // duos: ItemHistory[]
            // trios: ItemHistory[]
            // quads: ItemHistory[]
            // words: ItemHistory[]
            // characters: ItemHistory[]
          }
        }
      ) => {
        // this is a saga action watcher function to save stat in firebase
      },
    },
  })

export type ReturnTypePgSlice = ReturnType<typeof getSlice>

export const pgSlices = {
  [PG.MAIN_PG]: getSlice(PG.MAIN_PG),
  [PG.NUMBER_PRACTICE_PG]: getSlice(PG.NUMBER_PRACTICE_PG),
}

export const pgSelectors = {
  [PG.MAIN_PG]: (state: RootState) => state[PG.MAIN_PG],
  [PG.NUMBER_PRACTICE_PG]: (state: RootState) => state[PG.NUMBER_PRACTICE_PG],
}
