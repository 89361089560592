import { getUserRef } from 'config/database/refs'
import { DB_REF, DbSchema } from 'interface/database'
import { assertProd } from 'utils/assert-prod'
import { callSentryWithFirebaseError } from 'utils/sentry/call-sentry-with-firebase-error'

// chat: DbSchema['chatLoad'] means, chat: { timestamp: chat length }
export const updateAskAiChatLoad = async ({ uid, chat }: { uid: string; chat: DbSchema['chatLoad'] }) => {
  const fireDb = await import('firebase/database')

  const { getDatabase, ref, update } = fireDb

  const chatTimestamp = Object.keys(chat || {})[0]
  const chatMessage = Object.values(chat || {})[0]

  assertProd(!!chatTimestamp, 'chatTimestamp is required. (updateAskAiChatLoad)')
  assertProd(!!chatMessage, 'chatMessage is required. (updateAskAiChatLoad)')

  update(ref(getDatabase()), {
    [getUserRef(uid, { dbRef: DB_REF.chatLoad, firstNestedRef: chatTimestamp })]: chatMessage,
  }).catch(callSentryWithFirebaseError)
}
