import { API } from 'constants/api-routes'
import { INITIAL_EVALUATION_LESSON_ITEMS } from 'constants/lessons'

export const requestInitialEvaluationCopy = async (lessonName: INITIAL_EVALUATION_LESSON_ITEMS): Promise<string[]> => {
  try {
    const response = await fetch(`${API.INITIAL_EVALUATION}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ lessonName }),
    })

    const data = (await response.json()) as { initialCopy: string }

    return [data.initialCopy]
  } catch (error) {
    console.error(error)

    throw new Error(`Ai request saga failed.`)
  }
}

export {}
