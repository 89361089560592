export const MAX_MESSAGE_LENGTH = 1000
export const MAX_MESSAGE_COUNT_IN_3_HOURS = 25

// it is for the array of messages
export const MAX_MESSAGE_LENGTH_API_REQUEST = 2000

export const EVALUATION_BUTTON_TEXT = 'Start Evaluation'
export const AI_GUIDED_LESSON_BUTTON_TEXT = 'Generate AI Guided Lesson'

export const AI_BETA_EXPIRY_AFTER_DAYS = 7

// number of beta subscribers allowed
export const AI_BETA_SUBSCRIBERS_LIMIT = 50
