// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import * as Sentry from '@sentry/nextjs'
import { SentryPageTag } from 'constants/sentry'
import React from 'react'
import { callSentry } from 'utils/sentry/call-sentry'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    try {
      Sentry.configureScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key])
        })
      })
      Sentry.captureException(error)
    } catch {
      callSentry({
        pageTag: SentryPageTag.errorBoundary,
        message: 'Error boundary hit but could not send message to sentry on the try block',
      })
    }
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <div
          style={{
            border: '1px solid gray',
            textAlign: 'center',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '30vh',
          }}
        >
          <h1>Something went wrong!</h1>
          <h3 style={{ marginTop: '1rem' }}>Please reload the page. We&apos;re working to solve this issue.</h3>
        </div>
      )
    }
    // when there's not an error, render children untouched
    return this.props.children
  }
}
