import { assert } from 'utils/assert'

import { PgProps } from '../pgSlice'
import { getLastStrokeTimestamp } from './getLastStrokeTimestamp'

export /**
 * auto pause management:
 * update state for pause history and inProgress
 * @param state
 * @param now
 * @returns
 */
const handlePause = ({
  state,
  now,
  userTypedSomething,
  resumedAfterLastStrokeTimestamp,
}: {
  state: PgProps
  now: number
  userTypedSomething: boolean
  resumedAfterLastStrokeTimestamp?: number
}) => {
  // if typing just is beginning
  if (!state.strokes[0]?.endTimestamp) {
    if (userTypedSomething) state.inProgress = true
    return
  }

  const lastStrokeTimeStamp = getLastStrokeTimestamp(state)

  assert(!!lastStrokeTimeStamp, 'Something went wrong in the pg-slice, last stroke timestamp not found!')

  if (userTypedSomething) {
    if (resumedAfterLastStrokeTimestamp) {
      // duration of the latest pause
      state.pauseHistory[resumedAfterLastStrokeTimestamp] = now - resumedAfterLastStrokeTimestamp
    }
  }

  if (!userTypedSomething) {
    const timerExpired = now - lastStrokeTimeStamp >= state.pauseTimeout
    // if auto pause timer expired pause typing
    if (timerExpired) {
      // duration of the latest pause
      state.pauseHistory[lastStrokeTimeStamp] = now - lastStrokeTimeStamp
      state.inProgress = false
    }
  }
}
