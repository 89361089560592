import { createSlice } from '@reduxjs/toolkit'
import { MS_IN_MINUTE } from 'constants/calculations'
import { INITIAL_EVALUATION_LESSON_ITEMS, LESSON_NAME, LESSON_NAMES } from 'constants/lessons'
import { WeaknessProfile, WeaknessProfileItem } from 'constants/stats'
import { DbLessonStat, WeaknessStat } from 'interface/database'
import _isEqual from 'lodash/isEqual'
import _mean from 'lodash/mean'
import { createSelectorCreator, defaultMemoize } from 'reselect'
import type { RootState } from 'store'
import store2 from 'store2'
import { TopBottomCategories } from 'views/stats/components/top-bottom-keys/top-bottom-keys'

import { getTopBottomKeysProps, TopBottomCategoriesStats } from './utils/get-top-bottom-keys-props'
import { getRecentWeaknessStat } from './utils/getRecentWeaknessStat'

// Define a type for the slice state
export type StatProps = {
  // null should be there because in the case of new user the stats would come null from firebase
  stats: DbLessonStat[] | null
  uppercase: WeaknessStat | null
  lowercase: WeaknessStat | null
  num: WeaknessStat | null
  punctuation: WeaknessStat | null
  ngBi: WeaknessStat | null
  ngTri: WeaknessStat | null
  ng4: WeaknessStat | null
  cw1_50: WeaknessStat | null
  cw51_100: WeaknessStat | null
  cw101_150: WeaknessStat | null
  cw151_200: WeaknessStat | null
  cw201_250: WeaknessStat | null
  cw251_300: WeaknessStat | null
  cw301_400: WeaknessStat | null
  cw401_500: WeaknessStat | null
  cw501_600: WeaknessStat | null
  cw601_700: WeaknessStat | null
  cw701_800: WeaknessStat | null
  cw801_900: WeaknessStat | null
  cw901_1000: WeaknessStat | null
  loading: boolean
}

// Define the initial state using that type
const initialState: StatProps = Object.freeze({
  stats: null,
  uppercase: null,
  lowercase: null,
  num: null,
  punctuation: null,
  ngBi: null,
  ngTri: null,
  ng4: null,
  cw1_50: null,
  cw51_100: null,
  cw101_150: null,
  cw151_200: null,
  cw201_250: null,
  cw251_300: null,
  cw301_400: null,
  cw401_500: null,
  cw501_600: null,
  cw601_700: null,
  cw701_800: null,
  cw801_900: null,
  cw901_1000: null,

  // TODO: handle loading state based on all the stats being loaded
  loading: true,
})

const statSlice = createSlice({
  name: 'stat',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setStats: (state: StatProps, { payload }: { payload: DbLessonStat[] | null }) => {
      state.stats = payload
      state.loading = false
    },

    setUppercase: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.uppercase = payload
    },

    setLowercase: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.lowercase = payload
    },

    setNum: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.num = payload
    },

    setPunctuation: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.punctuation = payload
    },

    setNgBi: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.ngBi = payload
    },

    setNgTri: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.ngTri = payload
    },

    setNg4: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.ng4 = payload
    },

    setCw1_50: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.cw1_50 = payload
    },

    setCw51_100: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.cw51_100 = payload
    },

    setCw101_150: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.cw101_150 = payload
    },

    setCw151_200: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.cw151_200 = payload
    },

    setCw201_250: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.cw201_250 = payload
    },

    setCw251_300: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.cw251_300 = payload
    },

    setCw301_400: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.cw301_400 = payload
    },

    setCw401_500: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.cw401_500 = payload
    },

    setCw501_600: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.cw501_600 = payload
    },

    setCw601_700: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.cw601_700 = payload
    },

    setCw701_800: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.cw701_800 = payload
    },

    setCw801_900: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.cw801_900 = payload
    },

    setCw901_1000: (state: StatProps, { payload }: { payload: WeaknessStat | null }) => {
      state.cw901_1000 = payload
    },
  },
})

export const {
  setStats,
  setUppercase,
  setLowercase,
  setNum,
  setPunctuation,
  setNgBi,
  setNgTri,
  setNg4,
  setCw1_50,
  setCw51_100,
  setCw101_150,
  setCw151_200,
  setCw201_250,
  setCw251_300,
  setCw301_400,
  setCw401_500,
  setCw501_600,
  setCw601_700,
  setCw701_800,
  setCw801_900,
  setCw901_1000,
} = statSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectStatSlice = (state: RootState) => state.stat

// credit: https://github.com/reduxjs/reselect#customize-equalitycheck-for-defaultmemoize
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, _isEqual)

export const selectTestHistory = createDeepEqualSelector(selectStatSlice, (stat) => Object.values(stat.stats || {}))

// TODO: make sure it not running or recalculating if the stat hasn't updated
// selector for recent stats of avgWpm, avgAccuracy, recentTestCount, recentTestsTotalDuration
export const selectRecentStats = createDeepEqualSelector(selectTestHistory, (allTests) => {
  const recentTests = allTests.slice(-10)

  if (!recentTests.length) return null

  const totalWpm = recentTests.map((stat) => stat.wpm).reduce((a, b) => a + b, 0)
  const avgWpm = totalWpm / recentTests.length

  const totalAccuracy = recentTests.map((stat) => stat.accuracy).reduce((a, b) => a + b, 0)
  const avgAccuracy = totalAccuracy / recentTests.length

  return {
    avgWpm,
    avgAccuracy: avgAccuracy * 100,
    recentTestCount: recentTests.length,
    recentTestsTotalDuration:
      recentTests.map((stat) => Math.round((stat.finished - stat.started) / 1000) * 1000).reduce((a, b) => a + b, 0) /
      MS_IN_MINUTE,
  }
})

export const selectRecentWpm = createDeepEqualSelector(selectRecentStats, (stats) => stats?.avgWpm)

export const selectRecentAccuracy = createDeepEqualSelector(selectRecentStats, (stats) => stats?.avgAccuracy)

// lifetime testsTaken, totalDuration, bestSpeed
export const selectLifetimeStats = createDeepEqualSelector(selectTestHistory, (tests) => {
  const testsTaken = tests.length

  if (!tests.length)
    return {
      testsTaken,
      totalDuration: 0,
      bestSpeed: 0,
    }

  const totalDuration =
    tests
      .map((stat) => {
        // TODO: track if teh change was correct about the if !stat.duration
        // if stat.duration is undefined or stat.duration is 0 which was the case for the old calculation
        if (!stat.duration) {
          // remember this is the wrong way to calculate the duration (stat.finished - stat.started) because
          // if user starts and pauses in the middle and resumes after 5 minutes then the duration would be 5 minutes extra
          // We still need to kep it because earlier stat.duration was 0 in case of timed tests, but later on we
          // fixed that issues and now stat.duration is the actual duration of the test which is never 0 or undefined, correct?
          return Math.round((stat.finished - stat.started || 0) / 1000) * 1000
        }

        return stat.duration
      })
      .reduce((a, b) => a + b, 0) / MS_IN_MINUTE

  const bestSpeed = Math.max.apply(
    null,
    tests.map((test) => test.wpm)
  )

  return {
    testsTaken,
    totalDuration,
    bestSpeed,
  }
})

// average speed, accuracy, time taken on each lesson types (quote, paragraph, uppercase, numbers, etc)
export const selectRecentLessonsStats = createDeepEqualSelector(selectTestHistory, (tests) => {
  // loop through all the tests and create an object with the lesson type as the key and the value is an array of all the tests of that type
  const testsByType = tests.reduce((acc, test: DbLessonStat) => {
    if (!acc[test.name]) acc[test.name] = []
    acc[test.name].push(test)
    return acc
  }, {} as Record<LESSON_NAME, DbLessonStat[]>)

  // loop through the object and calculate the average speed, accuracy, and time taken for each lesson type
  const recentLessonsStats = Object.entries(testsByType).reduce((acc, [lessonType, tests]) => {
    const totalWpm = tests.map((stat) => stat.wpm).reduce((a, b) => a + b, 0)
    const avgWpm = totalWpm / tests.length

    const totalAccuracy = tests.map((stat) => stat.accuracy).reduce((a, b) => a + b, 0)
    const avgAccuracy = totalAccuracy / tests.length

    const totalDuration =
      tests.map((stat) => Math.round((stat.finished - stat.started) / 1000) * 1000).reduce((a, b) => a + b, 0) /
      MS_IN_MINUTE

    acc[lessonType as LESSON_NAME] = {
      avgWpm,
      avgAccuracy: avgAccuracy * 100,
      totalDuration,
    }

    return acc
  }, {} as Record<LESSON_NAME, { avgWpm: number; avgAccuracy: number; totalDuration: number }>)

  return recentLessonsStats
})

export const selectUppercaseStat = createDeepEqualSelector(selectStatSlice, (stat) =>
  getRecentWeaknessStat(stat.uppercase)
)

export const selectLowercase = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.lowercase))
export const selectNum = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.num))
export const selectPunctuation = createDeepEqualSelector(selectStatSlice, (stat) =>
  getRecentWeaknessStat(stat.punctuation)
)

export const selectNgBi = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.ngBi))
export const selectNgTri = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.ngTri))
export const selectNg4 = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.ng4))
export const selectCw1_50 = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.cw1_50))
export const selectCw51_100 = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.cw51_100))
export const selectCw101_150 = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.cw101_150))
export const selectCw151_200 = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.cw151_200))
export const selectCw201_250 = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.cw201_250))
export const selectCw251_300 = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.cw251_300))
export const selectCw301_400 = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.cw301_400))
export const selectCw401_500 = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.cw401_500))
export const selectCw501_600 = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.cw501_600))
export const selectCw601_700 = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.cw601_700))
export const selectCw701_800 = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.cw701_800))
export const selectCw801_900 = createDeepEqualSelector(selectStatSlice, (stat) => getRecentWeaknessStat(stat.cw801_900))
export const selectCw901_1000 = createDeepEqualSelector(selectStatSlice, (stat) =>
  getRecentWeaknessStat(stat.cw901_1000)
)

// Parameterized selector
export const selectTopAndBottomStats = createDeepEqualSelector(
  (state: RootState, characterTypes: TopBottomCategories[]): TopBottomCategoriesStats =>
    characterTypes.reduce((acc, characterType) => {
      acc[characterType] = selectStatSlice(state)[characterType]
      return acc
    }, {} as TopBottomCategoriesStats),

  getTopBottomKeysProps
)

enum WeaknessName {
  'character' = 'character',
  'bigram' = 'bigram',
  'trigram' = 'trigram',
  'fourgram' = 'fourgram',
  'word' = 'word',
}

type WeaknessRating =
  | null
  | {
      name: WeaknessName
      value: string
      rating: number
    }[]

// select weakest characters
export const selectWeakestCharacters = createDeepEqualSelector(
  [selectStatSlice, selectRecentWpm],
  (stat, recentWpm): WeaknessRating => {
    const lowercase = stat.lowercase // should be 1.5x of user's wpm
    // const uppercase = stat.uppercase // should be 0.75x of user's wpm
    // const num = stat.num // should be 0.5x of user's wpm
    // const punctuation = stat.punctuation // should be 0.5x of user's wpm

    if (!lowercase) return null

    if (recentWpm === undefined) return null

    // rate performance of each lowercase character on a scale of 100
    const ratedLowercase = Object.entries(lowercase || {}).map(([character, stats]) => {
      const avgLowercaseWpm = _mean(stats)
      const avgLowercaseWpmShouldBe = 1.5 * recentWpm
      const rating = Math.round((avgLowercaseWpm / avgLowercaseWpmShouldBe) * 100)
      return { name: WeaknessName.character, value: character, rating }
    })

    // // rate performance of each uppercase character on a scale of 100
    // const ratedUppercase = Object.entries(uppercase || {}).map(([character, stats]) => {
    //   const avgUppercaseWpm = _mean(stats)
    //   const avgUppercaseWpmShouldBe = 0.75 * recentWpm
    //   const rating = Math.round((avgUppercaseWpm / avgUppercaseWpmShouldBe) * 100)
    //   return { name: WeaknessName.character, value: character, rating }
    // })

    // // rate performance of each number character on a scale of 100
    // const ratedNum = Object.entries(num || {}).map(([character, stats]) => {
    //   const avgNumWpm = _mean(stats)
    //   const avgNumWpmShouldBe = 0.5 * recentWpm
    //   const rating = Math.round((avgNumWpm / avgNumWpmShouldBe) * 100)
    //   return { name: WeaknessName.character, value: character, rating }
    // })

    // // rate performance of each punctuation character on a scale of 100
    // const ratedPunctuation = Object.entries(punctuation || {}).map(([character, stats]) => {
    //   const avgPunctuationWpm = _mean(stats)
    //   const avgPunctuationWpmShouldBe = 0.5 * recentWpm
    //   const rating = Math.round((avgPunctuationWpm / avgPunctuationWpmShouldBe) * 100)
    //   return { name: WeaknessName.character, value: character, rating }
    // })

    // combine all ratings and sort by rating
    const allRatings = [
      ...ratedLowercase,

      // Exclude uppercase, numbers, and punctuation for now since generating tests for them is not implemented yet
      // more over it makes the lesson unattractive to the users
      // ...ratedUppercase,
      // ...ratedNum,
      // ...ratedPunctuation
    ]
      .filter((el) => el.rating < 100)
      .sort((a, b) => a.rating - b.rating)

    return allRatings
  }
)

// select weakest bigrams
export const selectWeakestBigrams = createDeepEqualSelector(
  [selectStatSlice, selectRecentWpm],
  (stat, recentWpm): WeaknessRating => {
    if (!recentWpm) return null

    const ngBi = stat.ngBi // should be 1.5x of user's wpm

    // rate performance of each bigram on a scale of 100
    const ratedBigrams = Object.entries(ngBi || {}).map(([bigram, stats]) => {
      const avgBigramWpm = _mean(stats)
      const avgBigramWpmShouldBe = 1.5 * recentWpm
      const rating = Math.round((avgBigramWpm / avgBigramWpmShouldBe) * 100)
      return { name: WeaknessName.bigram, value: bigram, rating }
    })

    return ratedBigrams.filter((el) => el.rating < 100).sort((a, b) => a.rating - b.rating)
  }
)

// select weakest bigrams and trigrams
export const selectWeakestBiAndTrigrams = createDeepEqualSelector(
  [selectStatSlice, selectRecentWpm],
  (stat, recentWpm): WeaknessRating => {
    if (!recentWpm) return null

    const ngrams = { ...stat.ngBi, ...stat.ngTri } // should be 1.5x of user's wpm

    // fourgrams
    // rate performance of each trigram on a scale of 100
    const ratedTrigrams = Object.entries(ngrams || {}).map(([trigram, stats]) => {
      const avgTrigramWpm = _mean(stats)
      const avgTrigramWpmShouldBe = 1.5 * recentWpm
      const rating = Math.round((avgTrigramWpm / avgTrigramWpmShouldBe) * 100)
      return { name: WeaknessName.trigram, value: trigram, rating }
    })

    return ratedTrigrams.filter((el) => el.rating < 100).sort((a, b) => a.rating - b.rating)
  }
)

// select weakest bigrams, trigrams, and fourgrams
export const selectWeakestBiTriAndFourgrams = createDeepEqualSelector(
  [selectStatSlice, selectRecentWpm],
  (stat, recentWpm): WeaknessRating => {
    if (!recentWpm) return null

    const ngrams = { ...stat.ngBi, ...stat.ngTri, ...stat.ng4 } // should be 1.5x of user's wpm

    // rate performance of each fourgram on a scale of 100
    const ratedFourgrams = Object.entries(ngrams || {}).map(([fourgram, stats]) => {
      const avgFourgramsWpm = _mean(stats)
      const avgFourgramsWpmShouldBe = 1.5 * recentWpm
      const rating = Math.round((avgFourgramsWpm / avgFourgramsWpmShouldBe) * 100)
      return { name: WeaknessName.fourgram, value: fourgram, rating }
    })

    return ratedFourgrams.filter((el) => el.rating < 100).sort((a, b) => a.rating - b.rating)
  }
)

// selected weakest 0-50 words (applicable for users with 20+ wpm)
export const selectWeakestWordsInCommon50 = createDeepEqualSelector(
  [selectStatSlice, selectRecentWpm],
  (stat, recentWpm): WeaknessRating => {
    if (!recentWpm) return null

    const words = stat.cw1_50 // should be 1.5x of user's wpm

    // rate performance of each word on a scale of 100
    const ratedWords = Object.entries(words || {}).map(([word, stats]) => {
      const avgWordWpm = _mean(stats)
      const avgWordWpmShouldBe = 1.5 * recentWpm
      const rating = Math.round((avgWordWpm / avgWordWpmShouldBe) * 100)
      return { name: WeaknessName.word, value: word, rating }
    })

    return ratedWords.filter((el) => el.rating < 100).sort((a, b) => a.rating - b.rating)
  }
)

// selected weakest 0-100 words (applicable for users with 40+ wpm)
export const selectWeakestWordsInCommon100 = createDeepEqualSelector(
  [selectStatSlice, selectRecentWpm],
  (stat, recentWpm): WeaknessRating => {
    if (!recentWpm) return null

    const cw50 = stat.cw1_50
    const cw100 = stat.cw51_100

    const top100 = {
      ...cw50,
      ...cw100,
    }

    // rate performance of each word on a scale of 100
    const ratedWords = Object.entries(top100).map(([word, stats]) => {
      const avgWordWpm = _mean(stats)
      const avgWordWpmShouldBe = 1.5 * recentWpm
      const rating = Math.round((avgWordWpm / avgWordWpmShouldBe) * 100)
      return { name: WeaknessName.word, value: word, rating }
    })

    return ratedWords.filter((el) => el.rating < 100).sort((a, b) => a.rating - b.rating)
  }
)

// select weakest 0-150 words (applicable for users with 60+ wpm)
export const selectWeakestWordsInCommon150 = createDeepEqualSelector(
  [selectStatSlice, selectRecentWpm],
  (stat, recentWpm): WeaknessRating => {
    if (!recentWpm) return null

    const cw50 = stat.cw1_50
    const cw100 = stat.cw51_100
    const cw150 = stat.cw101_150

    const top150 = {
      ...cw50,
      ...cw100,
      ...cw150,
    }

    // rate performance of each word on a scale of 100
    const ratedWords = Object.entries(top150).map(([word, stats]) => {
      const avgWordWpm = _mean(stats)
      const avgWordWpmShouldBe = 1.5 * recentWpm
      const rating = Math.round((avgWordWpm / avgWordWpmShouldBe) * 100)
      return { name: WeaknessName.word, value: word, rating }
    })

    return ratedWords.filter((el) => el.rating < 100).sort((a, b) => a.rating - b.rating)
  }
)

export const selectNextTestForInitialEvaluation = createDeepEqualSelector(
  selectStatSlice,
  (stat): INITIAL_EVALUATION_LESSON_ITEMS | null => {
    if (Object.keys(stat.lowercase || {}).length === 0) return LESSON_NAMES.LOWERCASE
    // if (Object.keys(stat.uppercase || {}).length === 0) return LESSON_NAMES.UPPERCASE
    // if (Object.keys(stat.num || {}).length === 0) return LESSON_NAMES.NUM
    // if (Object.keys(stat.punctuation || {}).length === 0) return LESSON_NAMES.PUNCTUATION
    if (Object.keys(stat.ngBi || {}).length === 0) return LESSON_NAMES.NG_BI
    if (Object.keys(stat.cw1_50 || {}).length === 0) return LESSON_NAMES.CW_1_50

    return null
  }
)

// lets define evaluation is underway if all the required stats are not present.
// Let's say lowercase, uppercase, num, punctuation, ngBi & cw1_50 are required
export const selectIsEvaluationUnderway = createDeepEqualSelector(
  selectNextTestForInitialEvaluation,
  // we assume that if nextTest is lowercase then evaluation is not underway, rather its a brand new user
  (nextTest) => nextTest !== null && nextTest !== LESSON_NAMES.LOWERCASE
)

export const selectIsUserBrandNew = createDeepEqualSelector(
  selectNextTestForInitialEvaluation,
  (nextTest) => nextTest === LESSON_NAMES.LOWERCASE
)

// write what this selector is doing
// this selector is used to generate the weakness profile for the user
// user with less than 40 wpm should only focus on characters, bigrams, and most common 50 words
// user with less than 60 wpm should only focus on characters, bigrams & trigrams, and top 100 words
// user with more than equal to 60 wpm should only focus on characters, bigrams+trigrams+fourgrams, and top 150 words
export const selectWeaknessProfile = createDeepEqualSelector(
  [
    selectRecentWpm,
    selectWeakestCharacters,
    selectWeakestBigrams,
    selectWeakestBiAndTrigrams,
    selectWeakestBiTriAndFourgrams,
    selectWeakestWordsInCommon50,
    selectWeakestWordsInCommon100,
    selectWeakestWordsInCommon150,
    selectIsEvaluationUnderway,
  ],
  (
    recentWpm,
    weakestCharacters,
    weakestBigrams,
    weakestBiAndTrigrams,
    weakestBiTriAndFourgrams,
    weakestWordsInCommon50,
    weakestWordsInCommon100,
    weakestWordsInCommon150,
    isEvaluationUnderway
  ): null | WeaknessProfile => {
    if (recentWpm === undefined) return null
    if (isEvaluationUnderway) return null

    const characters = (weakestCharacters ?? []).map((el) => el.value)

    const bigrams = (weakestBigrams ?? []).map((el) => el.value)
    const biAndTrigrams = (weakestBiAndTrigrams ?? []).map((el) => el.value)
    const biTriAndFourgrams = (weakestBiTriAndFourgrams ?? []).map((el) => el.value)
    // below 20wpm, don't need to practice bigrams.
    const ngrams = recentWpm < 20 ? [] : recentWpm < 40 ? bigrams : recentWpm < 60 ? biAndTrigrams : biTriAndFourgrams

    const wordsInCommon50 = (weakestWordsInCommon50 ?? []).map((el) => el.value)
    const wordsInCommon100 = (weakestWordsInCommon100 ?? []).map((el) => el.value)
    const wordsInCommon150 = (weakestWordsInCommon150 ?? []).map((el) => el.value)
    // below 20wpm, don't need to practice words.
    const words =
      recentWpm < 20 ? [] : recentWpm < 40 ? wordsInCommon50 : recentWpm < 60 ? wordsInCommon100 : wordsInCommon150

    // depending on the user's wpm, we return different weakness profile, and exclude the items that are not applicable
    return {
      characters: characters.slice(0, 5),
      ngrams: ngrams.slice(0, 5),
      words: words.slice(0, 5),
    }
  }
)

type StoredLastTakenLessonItem = {
  weaknessProfileItem: WeaknessProfileItem
  numberOfTimesTaken: number
}

const LAST_TAKEN_LESSON_ITEM = 'lastTakenLessonItem'

export type WeaknessItem = {
  weaknessProfileItem: WeaknessProfileItem
  weaknessItem: string | null
}

const getWeaknessProfileItem = ({
  weaknessProfile,
  weaknessItem,
  shouldResetCount,
}: {
  weaknessProfile: WeaknessProfile
  weaknessItem: WeaknessProfileItem
  shouldResetCount: boolean
}): WeaknessItem | null => {
  if (shouldResetCount) {
    store2.set(LAST_TAKEN_LESSON_ITEM, null)
  }

  switch (weaknessItem) {
    case WeaknessProfileItem.CHARACTERS: {
      store2.set(LAST_TAKEN_LESSON_ITEM, {
        weaknessProfileItem: WeaknessProfileItem.CHARACTERS,
        numberOfTimesTaken: (store2.get(LAST_TAKEN_LESSON_ITEM)?.numberOfTimesTaken || 0) + 1,
      })
      return {
        weaknessItem: weaknessProfile.characters[0] || null,
        weaknessProfileItem: WeaknessProfileItem.CHARACTERS,
      }
    }

    case WeaknessProfileItem.NGRAMS: {
      store2.set(LAST_TAKEN_LESSON_ITEM, {
        weaknessProfileItem: WeaknessProfileItem.NGRAMS,
        numberOfTimesTaken: (store2.get(LAST_TAKEN_LESSON_ITEM)?.numberOfTimesTaken || 0) + 1,
      })
      return {
        weaknessItem: weaknessProfile.ngrams[0] || null,
        weaknessProfileItem: WeaknessProfileItem.NGRAMS,
      }
    }

    case WeaknessProfileItem.WORDS: {
      store2.set(LAST_TAKEN_LESSON_ITEM, {
        weaknessProfileItem: WeaknessProfileItem.WORDS,
        numberOfTimesTaken: (store2.get(LAST_TAKEN_LESSON_ITEM)?.numberOfTimesTaken || 0) + 1,
      })
      return {
        weaknessItem: weaknessProfile.words[0] || null,
        weaknessProfileItem: WeaknessProfileItem.WORDS,
      }
    }

    default: {
      return null
    }
  }
}

// this selector is used to generate the next test for the user
// the user should be taking 2 lessons for characters, 2 lessons for ngrams, and 2 lessons for words,
// and 2 lessons for paragraphs and the repeat the cycle
export const selectWeaknessItemToGenerateTest = (weaknessProfile: null | WeaknessProfile): WeaknessItem | null => {
  if (!weaknessProfile) return null

  // the user should be taking 2 lessons for characters, 2 lessons for ngrams, and 2 lessons for words,
  // and 2 lessons for paragraphs and the repeat the cycle
  // also, remove the lesson item from the lesson plan if the user doesn't have any weakness in that lesson item
  let lessonPlan: WeaknessProfileItem[] = [
    WeaknessProfileItem.CHARACTERS,
    WeaknessProfileItem.NGRAMS,
    WeaknessProfileItem.WORDS,
  ]

  // if user doesn't have weak characters remove it from lesson plan
  if (weaknessProfile.characters.length === 0) {
    lessonPlan = lessonPlan.filter((lesson) => lesson !== WeaknessProfileItem.CHARACTERS)
  }

  // if user doesn't have weak ngrams remove it from lesson plan
  if (weaknessProfile.ngrams.length === 0) {
    lessonPlan = lessonPlan.filter((lesson) => lesson !== WeaknessProfileItem.NGRAMS)
  }

  // if user don't have weak words remove it from lesson plan
  if (weaknessProfile.words.length === 0) {
    lessonPlan = lessonPlan.filter((lesson) => lesson !== WeaknessProfileItem.WORDS)
  }

  const lastTakenLessonItem: StoredLastTakenLessonItem | null = store2.get(LAST_TAKEN_LESSON_ITEM)

  // if lastTakenLessonItem is null, then pick the first lesson from the lesson plan
  if (!lastTakenLessonItem) {
    // return the weakest n-grams to generate paragraph test, because API generates paragraph test for n-grams

    return getWeaknessProfileItem({ weaknessProfile, weaknessItem: WeaknessProfileItem.NGRAMS, shouldResetCount: true })
  }

  // if lastTakenLessonItem is not null, then pick the next lesson from the lesson plan
  const indexOfLastTakenLessonItem = lessonPlan.indexOf(lastTakenLessonItem.weaknessProfileItem)

  // check if the test was taken 1 times by using numberOfTimesTaken from lastTakenLessonItem
  // if yes, then move to the next lesson item in the lesson plan otherwise return the same lesson item
  if (lastTakenLessonItem.numberOfTimesTaken >= 1) {
    const nextLessonItem = lessonPlan[indexOfLastTakenLessonItem + 1]

    // if nextLessonItem is undefined, then it means we have reached the end of the lesson plan
    // so we should start from the beginning of the lesson plan
    if (!nextLessonItem) {
      return getWeaknessProfileItem({
        weaknessProfile,
        weaknessItem: WeaknessProfileItem.CHARACTERS,
        shouldResetCount: true,
      })
    }
    return getWeaknessProfileItem({ weaknessProfile, weaknessItem: nextLessonItem, shouldResetCount: true })
  } else {
    return getWeaknessProfileItem({
      weaknessProfile,
      weaknessItem: lastTakenLessonItem.weaknessProfileItem,
      shouldResetCount: false,
    })
  }
}

export { statSlice }
