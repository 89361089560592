import { PAGES } from 'constants/routes'

export const isDbConnectedPage = () => {
  // path.length - 1 to remove the trailing slash
  return [
    PAGES['/profile/'],
    PAGES['/lessons/'],
    PAGES['/stats/'],
    PAGES['/typing-practice/'],
    PAGES['/contact-us/'],
  ].some((path) => window.location.href.includes(path.slice(0, path.length - 1)))
}
