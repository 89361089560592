import { duos, quads, trios } from 'assets/api/n-gram'
import { punctuationChars } from 'assets/api/punctuation'
import { commonWords20k } from 'assets/api/word'
import { PG } from 'constants/pg'
import _isEmpty from 'lodash/isEmpty'
import _values from 'lodash/values'
import { select } from 'redux-saga/effects'
import { pgSelectors } from 'store/slices/pg-slice'
import { PgProps } from 'store/slices/pg-slice/pgSlice'
import { assert } from 'utils/assert'
import { assertProd } from 'utils/assert-prod'
import { sanitizeFirebaseKey } from 'utils/database/sanitize-firebase-key'
import { updateStats } from 'utils/database/update-stats'

import { getItemPerformance } from './stat.utils.get-item-performance'

type WhatYouYield = Promise<void>
type WhatYouReturn = void

// what you accept as a return from requestCopy
type WhatYouAccept = undefined

export function* handleSaveStat(action: {
  payload: { id: string; pgName: PG }
}): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
  const { pgName, id } = action.payload

  try {
    // You can also select a specific part of the state

    const pgStateWithoutType: any = yield select(pgSelectors[pgName] as any) as any

    const pgState: PgProps = pgStateWithoutType as PgProps

    const { rules, duration, timeElapsed, started, finished, wpm, accuracy, strokes, activeIndex, text } = pgState

    const targetText = text.slice(0, activeIndex)
    const targetStrokes = _values(strokes).slice(0, activeIndex)

    assert(typeof started === 'number', 'started should be defined as a number')
    assert(typeof finished === 'number', 'finished should be defined as a number')

    const lowercaseCharacterPerformance = 'abcdefghijklmnopqrstuvwxyz'
      .split('')
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    const uppercaseCharacterPerformance = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      .split('')
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    const numberPerformance = '0123456789'
      .split('')
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    const punctuationPerformance = punctuationChars
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [sanitizeFirebaseKey(curr.item)]: curr.wpm }), {})

    const duosPerformance = duos
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    const triosPerformance = trios
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    const quadsPerformance = quads
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    // words 0-50
    const word_0_50 = commonWords20k
      .slice(1, 50)
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    // words 51-100
    const word_51_100 = commonWords20k
      .slice(51, 100)
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    // words 101-150
    const word_101_150 = commonWords20k
      .slice(101, 150)
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    // words 151-200
    const word_151_200 = commonWords20k
      .slice(151, 200)
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    // words 201-250
    const word_201_250 = commonWords20k
      .slice(201, 250)
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    // words 251-300
    const word_251_300 = commonWords20k
      .slice(251, 300)
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    // words 301-400
    const word_301_400 = commonWords20k
      .slice(301, 400)
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    // words 401-500
    const word_401_500 = commonWords20k
      .slice(401, 500)
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    // words 501-600
    const word_501_600 = commonWords20k
      .slice(501, 600)
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    // words 601-700
    const word_601_700 = commonWords20k
      .slice(601, 700)
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    // words 701-800
    const word_701_800 = commonWords20k
      .slice(701, 800)
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    // words 801-900
    const word_801_900 = commonWords20k
      .slice(801, 900)
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    // words 901-1000
    const word_901_1000 = commonWords20k
      .slice(901, 1000)
      .map((item) => ({ item, wpm: getItemPerformance({ item, strokes: targetStrokes, text: targetText }) }))
      .filter((el) => !_isEmpty(el.wpm))
      .reduce((prev, curr) => ({ ...prev, [curr.item]: curr.wpm }), {})

    assertProd(Boolean(started), 'Invalid start time of the test')
    assertProd(Boolean(finished), 'Invalid finish time of the test')
    assertProd(Boolean(duration || timeElapsed), 'Invalid duration of the test')
    assertProd(Boolean(wpm), 'Invalid wpm of the test')
    assertProd(Boolean(accuracy), 'Invalid accuracy of the test')

    yield updateStats({
      id,
      newEntryLessonStats: {
        name: rules.lessonName,
        // if duration is zero, that implies that the test was not a timed test, therefore we use the timeElapsed
        duration: duration || timeElapsed,
        started: started as number,
        finished: finished as number,
        wpm,
        accuracy,
      },
      newEntryUppercase: uppercaseCharacterPerformance,
      newEntryLowercase: lowercaseCharacterPerformance,
      newEntryNum: numberPerformance,
      newEntryPunctuation: punctuationPerformance,
      newEntryNgBi: duosPerformance,
      newEntryNgTri: triosPerformance,
      newEntryNg4: quadsPerformance,
      newEntryCw1_50: word_0_50,
      newEntryCw51_100: word_51_100,
      newEntryCw101_150: word_101_150,
      newEntryCw151_200: word_151_200,
      newEntryCw201_250: word_201_250,
      newEntryCw251_300: word_251_300,
      newEntryCw301_400: word_301_400,
      newEntryCw401_500: word_401_500,
      newEntryCw501_600: word_501_600,
      newEntryCw601_700: word_601_700,
      newEntryCw701_800: word_701_800,
      newEntryCw801_900: word_801_900,
      newEntryCw901_1000: word_901_1000,
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export {}
