import { MutableRefObject, RefObject, useCallback, useEffect, useLayoutEffect, useRef } from 'react'

interface IUseEventListener {
  eventType: string
  listener: (event: Event) => void
  element?: RefObject<HTMLDivElement> | null
  wait?: number
}

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

export const useEventListener = ({ eventType, listener, element, wait = 500 }: IUseEventListener): void => {
  const throttleTimeout: MutableRefObject<ReturnType<typeof setTimeout> | null> = useRef(null)

  const callback = useCallback(
    (event: Event) => {
      listener(event)
      throttleTimeout.current = null
    },
    [listener]
  )

  useIsomorphicLayoutEffect(() => {
    const eventListener = (event: Event) => {
      if (throttleTimeout.current === null) {
        throttleTimeout.current = setTimeout(callback, wait, event)
      }
    }

    const targetElement = element ? element.current : window

    targetElement?.addEventListener(eventType, eventListener)
    return () => {
      const targetElement = element ? element.current : window
      targetElement?.removeEventListener(eventType, eventListener)
      if (throttleTimeout.current) clearTimeout(throttleTimeout.current)
    }
  }, [callback, element, eventType, wait])
}
