/**
 * Use assert() to assert state which your program assumes to be true.
 * Error messages and checks will be stripped in production.
 */

export const assert = (condition: boolean, message: string) => {
  if (process.env.NODE_ENV === 'development') {
    if (!condition) {
      console.error('ASSERTION_FAILED', message) // eslint-disable-line
      throw new Error(`ASSERTION_ERROR: ${message}`)
    }
  }
}
