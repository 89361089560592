import { WeaknessStat } from 'interface/database'

/**
 * calculates the average accuracy and average WPM based on the last 10 entries of each number[]
 * in the WeaknessStat object
 * @param stat
 * @returns
 */
export const getRecentWeaknessStat = (stat: WeaknessStat | null): { averageAccuracy: number; averageWpm: number } => {
  if (!stat) {
    return {
      averageAccuracy: 0,
      averageWpm: 0,
    }
  }

  let totalWpm = 0
  let totalCount = 0
  let totalErrors = 0

  Object.values(stat).forEach((wpmArray) => {
    const last10WpmArray = wpmArray.slice(-10)
    totalCount += last10WpmArray.length
    last10WpmArray.forEach((wpm) => {
      totalWpm += wpm
      if (wpm === 0) {
        totalErrors += 1
      }
    })
  })

  const averageAccuracy = totalCount > 0 ? 1 - totalErrors / totalCount : 0
  const averageWpm = totalCount > 0 ? totalWpm / totalCount : 0

  return {
    averageAccuracy: Math.round(averageAccuracy * 100), // convert to percentage
    averageWpm: Math.round(averageWpm),
  }
}
