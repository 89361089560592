import { PaletteMode } from 'components/app-theme-provider/interface'
import { LESSON_NAME } from 'constants/lessons'
import { AiMessage } from 'store/slices/ai/ai'

export enum RESEARCH_DB_REF {
  aiChatLogs = 'aiChatLogs',
}

export enum DB_REF {
  'adResumeTimestamp' = 'adResumeTimestamp',
  'paletteMode' = 'paletteMode',
  'showLiveStat' = 'showLiveStat',
  'showLiveWordPerf' = 'showLiveWordPerf',
  'showProgressBar' = 'showProgressBar',
  'showTypingHands' = 'showTypingHands',
  'testDuration' = 'testDuration',
  'firstName' = 'firstName',
  'lastName' = 'lastName',
  'userName' = 'userName',
  'email' = 'email',
  'address' = 'address',
  'dateOfBirth' = 'dateOfBirth',
  'aiSubscription' = 'aiSubscription',
  'aiBetaSubscribers' = 'aiBetaSubscribers',

  'chatLoad' = 'chatLoad',

  'stats' = 'stats',

  // Only store the items in the database if user type them.
  // we store it as a number[] which is wpm[] for each item. if user took more than 1 attempt to type that we assume it to be 0wpm
  'uppercase' = 'uppercase',
  'lowercase' = 'lowercase',
  'num' = 'num',
  'punctuation' = 'punctuation',
  'ngBi' = 'ngBi',
  'ngTri' = 'ngTri',
  'ng4' = 'ng4',
  'cw1_50' = 'cw1_50',
  'cw51_100' = 'cw51_100',
  'cw101_150' = 'cw101_150',
  'cw151_200' = 'cw151_200',
  'cw201_250' = 'cw201_250',
  'cw251_300' = 'cw251_300',
  'cw301_400' = 'cw301_400',
  'cw401_500' = 'cw401_500',
  'cw501_600' = 'cw501_600',
  'cw601_700' = 'cw601_700',
  'cw701_800' = 'cw701_800',
  'cw801_900' = 'cw801_900',
  'cw901_1000' = 'cw901_1000',
}

export type DB_SETTINGS_ITEMS = Exclude<
  DB_REF,
  | 'stats'
  | 'uppercase'
  | 'lowercase'
  | 'num'
  | 'punctuation'
  | 'ngBi'
  | 'ngTri'
  | 'ng4'
  | 'cw1_50'
  | 'cw51_100'
  | 'cw101_150'
  | 'cw151_200'
  | 'cw201_250'
  | 'cw251_300'
  | 'cw301_400'
  | 'cw401_500'
  | 'cw501_600'
  | 'cw601_700'
  | 'cw701_800'
  | 'cw801_900'
  | 'cw901_1000'
  | 'chatLoad'
  | 'aiSubscription'
  | 'aiBetaSubscribers'
>

export type AiSubscriptionType = null | 'beta' | 'trial' | 'subscriber'

export type AiSubscription = {
  type: AiSubscriptionType
  expiresAt: number
}

export interface DbSettings {
  [DB_REF.adResumeTimestamp]: number
  [DB_REF.paletteMode]: PaletteMode | null
  [DB_REF.showLiveStat]: boolean
  [DB_REF.showLiveWordPerf]: boolean
  [DB_REF.showProgressBar]: boolean
  [DB_REF.showTypingHands]: boolean
  [DB_REF.testDuration]: number
  [DB_REF.firstName]: string
  [DB_REF.lastName]: string
  [DB_REF.userName]: string
  [DB_REF.email]: string
  [DB_REF.address]: string
  [DB_REF.dateOfBirth]: number
}

// interface for storing typing test history
export interface DbLessonStat {
  name: LESSON_NAME
  duration: number
  started: number
  finished: number
  wpm: number
  accuracy: number
}

// on the client-side we store the wpm in an array of numbers
export type WeaknessStat = Record<string, number[]>
// on the database we store the wpm in an object of objects
export type DbWeaknessStat = Record<string, Record<string, number>>

// Only store the items in the database if user type them.
// we store it as a number[] which is wpm[] for each item. if user took more than 1 attempt to type that we assume it to be 0wpm
export interface WeaknessDetectingStats {
  [DB_REF.uppercase]: WeaknessStat
  [DB_REF.lowercase]: WeaknessStat
  [DB_REF.num]: WeaknessStat
  [DB_REF.punctuation]: WeaknessStat
  [DB_REF.ngBi]: WeaknessStat
  [DB_REF.ngTri]: WeaknessStat
  [DB_REF.ng4]: WeaknessStat
  [DB_REF.cw1_50]: WeaknessStat
  [DB_REF.cw51_100]: WeaknessStat
  [DB_REF.cw101_150]: WeaknessStat
  [DB_REF.cw151_200]: WeaknessStat
  [DB_REF.cw201_250]: WeaknessStat
  [DB_REF.cw251_300]: WeaknessStat
  [DB_REF.cw301_400]: WeaknessStat
  [DB_REF.cw401_500]: WeaknessStat
  [DB_REF.cw501_600]: WeaknessStat
  [DB_REF.cw601_700]: WeaknessStat
  [DB_REF.cw701_800]: WeaknessStat
  [DB_REF.cw801_900]: WeaknessStat
  [DB_REF.cw901_1000]: WeaknessStat
}

export interface DbSchema extends DbSettings, WeaknessDetectingStats {
  [DB_REF.stats]?: DbLessonStat[]

  // how many messages sent in a chat
  [DB_REF.chatLoad]?: Record<string /* timestamps */, { length: number /* length of the message */ }>

  // ai subscription
  [DB_REF.aiSubscription]?: AiSubscription
}

export interface ResearchDbSchema {
  [RESEARCH_DB_REF.aiChatLogs]: {
    // store chat logs
    [userId: string]: Record<
      string /* Thread start timestamp, it will ensure we don't store duplicate chats */,
      { messages: AiMessage[] }
    >
  }
}
