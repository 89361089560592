import { DB_SETTINGS_ITEMS } from 'interface/database'

export const SETTINGS_DEFAULT: Record<DB_SETTINGS_ITEMS, unknown> = {
  adResumeTimestamp: 0,
  paletteMode: 'dark',
  showLiveStat: true,
  showLiveWordPerf: true,
  showProgressBar: true,
  showTypingHands: true,
  testDuration: 60000,
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  address: '',
  dateOfBirth: 0,
}
