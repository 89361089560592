import { createSlice } from '@reduxjs/toolkit'
import { AI_GUIDED_LESSON_BUTTON_TEXT, EVALUATION_BUTTON_TEXT } from 'constants/ai'
import { RootState } from 'store'

export type AskAiPayload = { uid: string; userMessage: string }

export type AiMessage = { role: 'user' | 'assistant'; content: string }

// Define a type for the slice State
export type AiProps = {
  askAi: {
    messages: AiMessage[]
    threadStartTimestamp: number | null
    loading: boolean
    error: string | null
    messageCountInLast3Hours: number
  }
}

// Define the initial State using that type
const initialState: AiProps = Object.freeze({
  askAi: {
    messages: [],
    threadStartTimestamp: null,
    loading: false,
    error: null,
    messageCountInLast3Hours: 0,
  },
})

const aiSlice = createSlice({
  name: 'ai',
  // `createSlice` will infer the State type from the `initialState` argument
  initialState,
  reducers: {
    setAiMessageError: (state, action) => {
      console.log('action.payload error:', action.payload)

      state.askAi.error = action.payload
      state.askAi.loading = false
    },
    setAiMessages: (state, action: { payload: string }) => {
      const { payload } = action
      console.log('setAiMessage', payload)

      state.askAi.messages = [...state.askAi.messages, { role: 'assistant', content: payload }]

      state.askAi.loading = false
      state.askAi.error = null
    },
    askAi: (state, action: { payload: AskAiPayload }) => {
      const { userMessage } = action.payload

      // this function is for saga watcher
      state.askAi.loading = true

      if (state.askAi.messages.length === 0 && state.askAi.threadStartTimestamp === null) {
        state.askAi.threadStartTimestamp = Date.now()
      }

      state.askAi.messages = [...state.askAi.messages, { role: 'user', content: userMessage }]
    },

    setMessageCountInLast3Hours(state, action: { payload: number }) {
      state.askAi.messageCountInLast3Hours = action.payload
    },
  },
})

export const { askAi, setAiMessages, setAiMessageError, setMessageCountInLast3Hours } = aiSlice.actions

export { aiSlice }

export const selectStatSlice = (state: RootState) => state.ai

export const selectAiMessages = (state: RootState) => state.ai.askAi.messages

export const selectShouldShowEvaluationButton = (state: RootState) =>
  state.ai.askAi.messages
    .filter((el) => el.role === 'assistant')
    .some((el) => el.content.includes(`${EVALUATION_BUTTON_TEXT}`))

export const selectShouldShowAiGuidedLessonButton = (state: RootState) =>
  state.ai.askAi.messages
    .filter((el) => el.role === 'assistant')
    .some((el) => el.content.includes(`${AI_GUIDED_LESSON_BUTTON_TEXT}`))

export const selectThreadStartTimestamp = (state: RootState) => state.ai.askAi.threadStartTimestamp
export const selectAiError = (state: RootState) => state.ai.askAi.error
export const selectAiMessagesLoading = (state: RootState) => state.ai.askAi.loading
export const selectAiMessagesCountInLast3Hours = (state: RootState) => state.ai.askAi.messageCountInLast3Hours
