import { Palette, PaletteMode } from '../interface'
import {
  black,
  darkGray,
  gray,
  grayC,
  grayWhitish,
  green1,
  green2,
  licorice,
  licoriceDark,
  licoriceLessDark,
  licoriceLight,
  licoriceLighter,
  lightGray,
  red1,
  red2,
  turquoise,
  white,
} from './colors'

export const paletteDark: Palette = {
  mode: PaletteMode.dark,
  background: {
    default: licoriceDark,
    paper: black,
  },
  text: {
    primary: grayWhitish,
    secondary: darkGray,
    selection: licorice,
    error: red1,
  },
  primary: {
    main: licorice,
    contrastText: grayWhitish,
    light: licoriceLight,
  },
  success: {
    main: green1,
    light: green2,
    contrastText: white,
  },
  error: {
    main: red1,
    light: red2,
    contrastText: lightGray,
    errorPageColor: grayWhitish,
  },
  border: {
    light: lightGray,
    main: gray,
  },
  shadow: {
    main: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  disabled: {
    main: licoriceLessDark,
    contrastText: licoriceLighter,
    border: grayC,
  },
  link: {
    main: turquoise,
  },
}
