export enum SentryPageTag {
  loader = 'Loader',
  profile = 'Profile',
  errorBoundary = 'Error Boundary',
  contactUs = 'Contact Us',
  articlePage = 'Article Page View',
  _app = '_app',
  fromPg = 'From Pg',
  askAIModal = 'Ask AI Modal',
  apiAiLessons = 'apiAiLessons',
}
