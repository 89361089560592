import { API } from 'constants/api-routes'
import { AiCopyOptions } from 'pages/api/ai-copy/utils/interface'

export const requestAiCopy = async (aiOptions: AiCopyOptions): Promise<string[]> => {
  console.log({ aiOptions })

  try {
    const response = await fetch(`${API.AI_COPY}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ aiOptions }),
    })

    const data = (await response.json()) as { aiGuidedExercise: string }

    return [data.aiGuidedExercise]
  } catch (error) {
    console.error(error)

    throw new Error(`Ai request saga failed.`)
  }
}

export {}
