import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'

// List of UIDs
export type SetAiBetaSubscribersPayload = string[]

// Define a type for the slice State
export type AiBetaSubscribersProps = {
  aiBetaSubscribers: string[]
  aiBetaSubscribersLoading: boolean
  aiBetaSubscribersLoaded: boolean
  aiBetaSubscribersError: string | null
}

const initialState: AiBetaSubscribersProps = Object.freeze({
  aiBetaSubscribers: [],
  aiBetaSubscribersLoading: false,
  aiBetaSubscribersLoaded: false,
  aiBetaSubscribersError: null,
})

const aiBetaSubscribersSlice = createSlice({
  name: 'aiBetaSubscribers',
  initialState,
  reducers: {
    setAiBetaSubscribersLoading: (state, action: { payload: boolean }) => {
      const { payload } = action
      state.aiBetaSubscribersLoading = payload
      state.aiBetaSubscribersLoaded = false
    },
    addAiBetaSubscriber: (_state, _action: { payload: { uid: string } }) => {
      // saga watcher, you should call this with the payload from the App
    },
    __setAiBetaSubscribers: (state, action: { payload: string[] }) => {
      state.aiBetaSubscribers = action.payload
      state.aiBetaSubscribersLoading = false
      state.aiBetaSubscribersLoaded = true
      state.aiBetaSubscribersError = null
    },
    setAiBetaSubscribersError: (state, action: { payload: string }) => {
      state.aiBetaSubscribersLoading = false
      state.aiBetaSubscribersError = action.payload
    },
  },
})

export const { setAiBetaSubscribersLoading, addAiBetaSubscriber, __setAiBetaSubscribers, setAiBetaSubscribersError } =
  aiBetaSubscribersSlice.actions

export { aiBetaSubscribersSlice }

export const selectAiBetaSubscribersSlice = (state: RootState) => state.aiBetaSubscribers

// keys are the UIDs
export const selectAiBetaSubscribers = (state: RootState) =>
  Object.keys(selectAiBetaSubscribersSlice(state).aiBetaSubscribers)
export const selectAiBetaSubscribersLoading = (state: RootState) =>
  selectAiBetaSubscribersSlice(state).aiBetaSubscribersLoading
export const selectAiBetaSubscribersLoaded = (state: RootState) =>
  selectAiBetaSubscribersSlice(state).aiBetaSubscribersLoaded
export const selectAiBetaSubscribersError = (state: RootState) =>
  selectAiBetaSubscribersSlice(state).aiBetaSubscribersError
