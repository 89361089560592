// note: this util not only transforms to lowercase but also removes unwanted characters and
// replaces consecutive whitespace characters with a single space
export const lowerCaseOnly = (input: string): string => {
  // Remove unwanted characters
  const cleanString = input.replace(/[^a-zA-Z\s]+/g, '')

  // Replace consecutive whitespace characters with a single space
  const singleSpaceString = cleanString.replace(/\s{2,}/g, ' ')

  // Capitalize and trim whitespace
  const transformedString = singleSpaceString.toLowerCase().trim()

  return transformedString
}
