import { MS_IN_MINUTE } from 'constants/calculations'
import { Stroke } from 'interface/pg'
import { assert } from 'utils/assert'

import { GetWordDetailsReturn, TypeCharacter } from './../interface'

export interface ArgsGetWordDetails {
  // eventually after all tests and optimization we will remove this word prop, as we can get this from strokesOfTheWord array
  word: string
  strokesInWord: Stroke[]
}

// optimize
export const getWordDetails = (args: ArgsGetWordDetails): GetWordDetailsReturn => {
  const { word, strokesInWord } = args

  let wordToBeTyped = ''

  const characterDetails: TypeCharacter[] = []
  let actuallyTypedWord = ''

  // if word typing is not finished yet, return immediately
  if (!strokesInWord[strokesInWord.length - 1]?.endTimestamp) {
    return {
      word,
      characterDetails: [],
    }
  }

  // using numberOfAttempts we can detect if user made correction to the word
  let numberOfAttempts = 0

  strokesInWord.forEach((stroke, i) => {
    assert(!!stroke, 'Debug why "stroke" is not available on getWordDetails util.')

    const { characterToBeTyped, actuallyTypedCharacter, attempts } = stroke

    numberOfAttempts += attempts.length

    wordToBeTyped += characterToBeTyped

    characterDetails.push({
      index: i,
      toType: characterToBeTyped,
      actuallyTyped: actuallyTypedCharacter,
    })

    actuallyTypedWord += actuallyTypedCharacter
  })

  // we will eventually remove this check once we complete this util function.
  if (word !== wordToBeTyped) {
    throw new Error('found word and props word should be exactly same')
  }

  // get the total time taken by adding each character duration
  const totalTimeTaken = strokesInWord.reduce((prev, curr) => {
    const endTimestamp: number = curr.endTimestamp as number

    assert(
      !!endTimestamp,
      'endTimestamp must be there at this point since this line executes only if full word been typed '
    )

    return prev + (endTimestamp - curr.startTimestamp)
  }, 0)

  const eachCharacterMs = totalTimeTaken / word.length
  const characterPerMinute = MS_IN_MINUTE / eachCharacterMs
  // let assume 5 character makes a word on an avg
  const wpm = characterPerMinute / 5

  return {
    word: wordToBeTyped,
    actuallyTypedWord,
    wpm,
    isCorrect: actuallyTypedWord.length === wordToBeTyped.length ? actuallyTypedWord === wordToBeTyped : undefined,
    characterDetails,
    madeCorrection: numberOfAttempts > wordToBeTyped.length,
  }
}
