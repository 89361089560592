// credit: https://stackoverflow.com/a/51398471/7562739

import { call, put } from 'redux-saga/effects'
import { AiMessage } from 'store/slices/ai/ai'
import { setAiSubscriptionError, SetAiSubscriptionPayload } from 'store/slices/subscription/subscription'
import { updateAiSubscription } from 'utils/database/update-ai-subscription'

// for put effect PutEffect<PayloadAction<CopyResponse>> can be used and import be like ---> import { AnyAction } from 'redux';
type WhatYouYield = Promise<void> | { payload: any; type: string }
type WhatYouReturn = void

type WhatYouAcceptFromAI = number | AiMessage[] | string

export function* handleUpdateAiSubscription(action: {
  payload: SetAiSubscriptionPayload
}): Generator<WhatYouYield, WhatYouReturn, WhatYouAcceptFromAI> {
  try {
    const { uid, type, expiresAt } = action.payload

    // update AI subscription status of the user
    yield call(updateAiSubscription, { uid, aiSubscription: { type, expiresAt } })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)

    yield put(
      setAiSubscriptionError(
        "Couldn't update subscription status. Please try again. If the problem persists, please contact support@typingmentor.com."
      )
    )
  }
}

export {}
