import { Stroke } from 'interface/pg'
import _isNumber from 'lodash/isNumber'

/**
 * Get the performance (in words per minute) of a specific item (e.g., single character, bi-gram, word) based
 * on the provided typing history (strokes) and text up to the current active index.
 *
 * @param {Object} params - The function parameters.
 * @param {string} params.item - The item to analyze (e.g., 'a', 'ae', 'even', etc.).
 * @param {Stroke[]} params.strokes - The typing history containing stroke information.
 * @param {string} params.text - The practice copy up to the active index.
 * @returns {number[]} An array of wpm values for each occurrence of the item in the text.
 */
export const getItemPerformance = ({
  item,
  strokes,
  text,
}: {
  // example: 'a', duos 'ae', words 'even', etc
  item: string
  strokes: Stroke[]
  // the practice copy up to activeIndex
  text: string
}): number[] => {
  const result: number[] = []

  if (!item) return []

  // Loop through the text to search for the given item
  for (let textIndex = 0; textIndex <= text.length - item.length; textIndex++) {
    const textSubstring = text.slice(textIndex, textIndex + item.length)

    // If the item is found in the text starting from the current textIndex
    if (textSubstring === item) {
      const itemStartIdex = textIndex
      const itemEndIndex = textIndex + item.length - 1

      // Check if all characters in the item are typed correctly
      let allCorrectlyTyped = true
      for (let strokeIndex = itemStartIdex; strokeIndex <= itemEndIndex; strokeIndex++) {
        const stroke = strokes[strokeIndex]

        // If any of the strokes are not correctly typed, set allCorrectlyTyped to false and break the loop
        if (stroke.attempts.length > 1 || !stroke.correctlyTyped) {
          allCorrectlyTyped = false
          break
        }
      }

      // If not all characters are typed correctly, push 0 as wpm and skip to the next item
      if (!allCorrectlyTyped) {
        result.push(0)
        continue
      }

      // Calculate the wpm for the correctly typed item
      const firstCharStarted = strokes[itemStartIdex]?.startTimestamp
      const lastCharTimestamp = strokes[itemEndIndex]?.endTimestamp

      if (firstCharStarted === lastCharTimestamp) {
        continue
      }

      // If both first and last character timestamps exist, calculate and push the wpm value
      if (_isNumber(firstCharStarted) && _isNumber(lastCharTimestamp)) {
        const calculatedWpm = item.length / 5 / ((lastCharTimestamp - firstCharStarted) / 1000 / 60)

        const wpm = Math.round(calculatedWpm)

        result.push(wpm)
      } else {
        // If timestamps are missing, push 0 as wpm
        result.push(0)
      }
    }
  }

  return result
}
