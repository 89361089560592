import { LESSON_NAMES } from 'constants/lessons'

// the stats are items that we can calculate for weaknesses, like we might want to know the weakest finger, or the weakest character
export type STATS_ITEMS =
  | LESSON_NAMES.UPPERCASE
  | LESSON_NAMES.LOWERCASE
  | LESSON_NAMES.NUM
  | LESSON_NAMES.PUNCTUATION
  | LESSON_NAMES.H_LEFT
  | LESSON_NAMES.H_RIGHT
  | LESSON_NAMES.NG_BI
  | LESSON_NAMES.NG_TRI
  | LESSON_NAMES.NG_4
  | LESSON_NAMES.R_NUM
  | LESSON_NAMES.R_TOP
  | LESSON_NAMES.R_HOME
  | LESSON_NAMES.R_BOTTOM
  | LESSON_NAMES.F_L_PINKY
  | LESSON_NAMES.F_L_RING
  | LESSON_NAMES.F_L_MID
  | LESSON_NAMES.F_L_INDEX
  | LESSON_NAMES.F_R_PINKY
  | LESSON_NAMES.F_R_RING
  | LESSON_NAMES.F_R_MID
  | LESSON_NAMES.F_R_INDEX
  | LESSON_NAMES.CW_1_50
  | LESSON_NAMES.CW_51_100
  | LESSON_NAMES.CW_101_150
  | LESSON_NAMES.CW_151_200
  | LESSON_NAMES.CW_201_250
  | LESSON_NAMES.CW_251_300
  | LESSON_NAMES.CW_301_400
  | LESSON_NAMES.CW_401_500
  | LESSON_NAMES.CW_501_600
  | LESSON_NAMES.CW_601_700
  | LESSON_NAMES.CW_701_800
  | LESSON_NAMES.CW_801_900
  | LESSON_NAMES.CW_901_1000
  | LESSON_NAMES.LONG_WORD_H_LEFT
  | LESSON_NAMES.LONG_WORD_H_RIGHT
  | LESSON_NAMES.ONE_MIN
  | LESSON_NAMES.ALPHABET
  | LESSON_NAMES.M_DUNN
  | LESSON_NAMES.A_LINCOLN
  | LESSON_NAMES.M_ANGELOU
  | LESSON_NAMES.M_TERESA
  | LESSON_NAMES.J_LENNON
  | LESSON_NAMES.N_MENDELA
  | LESSON_NAMES.CONFUCIUS
  | LESSON_NAMES.T_A_EDISON
  | LESSON_NAMES.H_D_THOREAU
  | LESSON_NAMES.W_S_CHURCHILL
  | LESSON_NAMES.D_BRINKLEY
  | LESSON_NAMES.G_CHAHAL

export enum WeaknessProfileItem {
  CHARACTERS = 'characters',
  NGRAMS = 'ngrams',
  WORDS = 'words',
}

export type WeaknessProfile = {
  [WeaknessProfileItem.CHARACTERS]: string[] // 5 weak lowercase letters
  [WeaknessProfileItem.NGRAMS]: string[] // 5 weak ngrams based on user's wpm level
  [WeaknessProfileItem.WORDS]: string[] // 5 weak words based on user's wpm level
}
