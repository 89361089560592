// credit: https://redux-toolkit.js.org/usage/usage-with-typescript#wrapping-createslice
// credit: https://github.com/reduxjs/redux-toolkit/issues/715

import { createSlice, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'

export const createGenericSlice = <T, Reducers extends SliceCaseReducers<T>>({
  name = '',
  initialState,
  reducers,
}: {
  name: string
  reducers: ValidateSliceCaseReducers<T, Reducers>
  initialState: T
}) =>
  createSlice({
    name,
    initialState,
    reducers,
  })
