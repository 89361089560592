// credit: https://stackoverflow.com/a/51398471/7562739

import { call, put } from 'redux-saga/effects'
import { AiMessage } from 'store/slices/ai/ai'
import { setAiBetaSubscribersError } from 'store/slices/ai-beta-subscribers/ai-beta-subscribers'
import { addAiBetaSubscriber } from 'utils/database/update-ai-beta-subscribers'

// for put effect PutEffect<PayloadAction<CopyResponse>> can be used and import be like ---> import { AnyAction } from 'redux';
type WhatYouYield = Promise<void> | { payload: any /* uid of the new subscriber */ }
type WhatYouReturn = void

type WhatYouAcceptFromAI = number | AiMessage[] | string

export function* handleAddAiBetaSubscriber(action: {
  payload: { uid: string }
}): Generator<WhatYouYield, WhatYouReturn, WhatYouAcceptFromAI> {
  try {
    // update total AI beta subscribers list
    yield call(addAiBetaSubscriber, { uid: action.payload.uid })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)

    yield put(
      setAiBetaSubscribersError(
        "Couldn't update subscription status. Please try again. If the problem persists, please contact support@typingmentor.com."
      )
    )
  }
}

export {}
