import { PG } from 'constants/pg'
import { takeLatest } from 'redux-saga/effects'
import { aiSlice } from 'store/slices/ai/ai'
import { addAiBetaSubscriber } from 'store/slices/ai-beta-subscribers/ai-beta-subscribers'
import { pgSlices } from 'store/slices/pg-slice'
import { settingSlice } from 'store/slices/setting'
import { setAiSubscription } from 'store/slices/subscription/subscription'

import { handleAskAi } from './handlers/ai/ask-ai'
import { handleAddAiBetaSubscriber } from './handlers/ai-beta-subscribers/ai-beta-subscribers'
import { handleFetchInAdvance, handleGetCopy } from './handlers/copy'
import { handleSetting } from './handlers/setting'
import { handleSaveStat } from './handlers/stat'
import { handleUpdateAiSubscription } from './handlers/subscription/subscription'

export function* watcherSaga() {
  yield takeLatest(settingSlice.actions.setAdResumeTimestamp, handleSetting)
  yield takeLatest(settingSlice.actions.setShowLiveStat, handleSetting)
  yield takeLatest(settingSlice.actions.setShowLiveWordPerf, handleSetting)
  yield takeLatest(settingSlice.actions.setShowProgressBar, handleSetting)
  yield takeLatest(settingSlice.actions.setShowTypingHands, handleSetting)
  yield takeLatest(settingSlice.actions.setTestDuration, handleSetting)
  yield takeLatest(settingSlice.actions.setFirstName, handleSetting)
  yield takeLatest(settingSlice.actions.setLastName, handleSetting)
  yield takeLatest(settingSlice.actions.setUserName, handleSetting)
  yield takeLatest(settingSlice.actions.setEmail, handleSetting)
  yield takeLatest(settingSlice.actions.setAddress, handleSetting)
  yield takeLatest(settingSlice.actions.setDateOfBirth, handleSetting)

  yield takeLatest(addAiBetaSubscriber, handleAddAiBetaSubscriber)
  yield takeLatest(setAiSubscription, handleUpdateAiSubscription)

  // AI data
  yield takeLatest(aiSlice.actions.askAi, handleAskAi)

  /**
   * each PG should have one handleGetCopy, handleFetchInAdvance, and handleSaveStat
   */
  yield takeLatest(pgSlices[PG.MAIN_PG].actions.saveResult, handleSaveStat)
  yield takeLatest(pgSlices[PG.MAIN_PG].actions.handleRestart, handleGetCopy)
  yield takeLatest(pgSlices[PG.MAIN_PG].actions.fetchInAdvance, handleFetchInAdvance)

  yield takeLatest(pgSlices[PG.NUMBER_PRACTICE_PG].actions.saveResult, handleSaveStat)
  yield takeLatest(pgSlices[PG.NUMBER_PRACTICE_PG].actions.handleRestart, handleGetCopy)
  yield takeLatest(pgSlices[PG.NUMBER_PRACTICE_PG].actions.fetchInAdvance, handleFetchInAdvance)
}
