import { VALID_CHARS } from 'constants/pg'

export const getActiveIndex = (prevActiveIndex: number, key: string) => {
  switch (true) {
    // backspace
    case key === 'Backspace':
      return prevActiveIndex - 1

    default:
      return VALID_CHARS.includes(key) ? prevActiveIndex + 1 : prevActiveIndex
  }
}
