import * as Sentry from '@sentry/nextjs'
import { IS_DEV } from 'constants/app'
import toast from 'react-hot-toast'

// this util calls sentry with an error that firebase returns.
// this utils shows a toast message
export const callSentryWithFirebaseError = ({
  message,
}: {
  code: string // "PERMISSION_DENIED"
  message: string // "PERMISSION_DENIED: Permission denied"
  stack: string // Error: PERMISSION_DENIED: Permission denied\n    at eval (webpack-internal:///./node_modules/@fireba...
}) => {
  if (IS_DEV) {
    // eslint-disable-next-line no-console
    console.log('callSentryWithFirebaseError', { message })
  }

  toast['error'](
    'Something went wrong! The issue has been reported and the developers are working to fix it as soon as possible.'
  )

  Sentry.captureException(message)
}
