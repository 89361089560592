import { Strokes } from '../interface'

const MAX_CONSECUTIVE_WRONG = 3

export const tooManyConsecutiveWrong = ({
  strokes,
  typedIndex,
  actuallyTypedCharacter,
}: {
  strokes: Strokes
  typedIndex: number
  // pass the last strokes typed character since stoke object here doesn't have that information for the last hit
  actuallyTypedCharacter: string
}) => {
  // at first key hit, we get strokes={}, then on second hit we get both first and second
  // strokes in the strokes object but the second stroke only container the property called
  // characterToBeTyped.However, let's return false if minimum 3 char has not been typed
  if (typedIndex < MAX_CONSECUTIVE_WRONG) return false

  if (actuallyTypedCharacter === strokes[typedIndex]?.characterToBeTyped) return false

  // there should MAX_CONSECUTIVE_WRONG number of lines of if statements like below
  if (strokes[typedIndex - 1]?.correctlyTyped === true) return false
  if (strokes[typedIndex - 2]?.correctlyTyped === true) return false
  if (strokes[typedIndex - 3]?.correctlyTyped === true) return false

  return true
}
