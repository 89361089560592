// credit: https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/lib/gtag.js

import * as Sentry from '@sentry/nextjs'
import { EVENT } from 'constants/gtm'
import { LESSON_NAME } from 'constants/lessons'
import { PG } from 'constants/pg'
import _kebabCase from 'lodash/kebabCase'

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  name,
  lessonName,
  duration,
  pgName,
}: {
  name: EVENT
  lessonName?: LESSON_NAME
  duration?: number
  pgName?: PG
}) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.dataLayer.push({
      event: name,
      test_duration: duration,
      lesson_name: lessonName !== undefined && _kebabCase(lessonName),
      pg_name: pgName !== undefined && _kebabCase(pgName),
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}
