import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  *, 
  *::before, 
  *::after {
     box-sizing: border-box;
  }
  
  * {
    margin: 0;
    padding: 0;
  }

  html, body {
    height: 100%;
  }

  body {    
    ${({ theme }) => theme.typography.body1};
    color: ${({ theme }) => theme.palette.text.primary};
    background: ${({ theme }) => theme.palette.background.default};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  input, button, textarea, select {
    font: inherit;
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  #root, #__next {
    isolation: isolate;
  }


  a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.link.main}
  }

    /* TODO: a default selection styling should be set here */
  ::selection {
    background-color: black;
    color: white;
  }
  
  input[type='checkbox'] {
    accent-color:  ${({ theme }) => theme.palette.primary.main};
  }
`
