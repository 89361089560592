import { Stroke } from 'interface/pg'

import { TypeCharacter } from '../interface'

export const getActiveWordCharacterDetails = ({
  activeWord,
  activeWordStartIndex,
  strokes,
}: {
  activeWord: string
  activeWordStartIndex: number
  strokes: Stroke[]
}) => {
  return activeWord.split('').map((char, index) => {
    const indexOfActiveChar = index + activeWordStartIndex
    const activeChar: TypeCharacter = {
      index: indexOfActiveChar,
      toType: char,
      actuallyTyped: strokes[indexOfActiveChar]?.actuallyTypedCharacter,
    }

    return activeChar
  })
}
