import { IS_CYPRESS } from 'constants/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { getAuth } from 'firebase/auth'
import firebase from 'firebase/compat/app'

// Configure Firebase.
const config = {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY!,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
}
const app = firebase.initializeApp(config)

// Cal the get auth without any reason here, otherwise the auth is not initialized
// Related issue: https://stackoverflow.com/questions/67687012/permission-denied-after-login-in-angular-angularfire-app
getAuth(app)

if (typeof window !== 'undefined' && !IS_CYPRESS) {
  // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  // key is the counterpart to the secret key you set in the Firebase console.
  initializeAppCheck(app, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    provider: new ReCaptchaV3Provider(process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY!),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
  })
}

export const getInitializedFirebaseApp = () => app
