import { getAiChatLogRef } from 'config/database/refs'
import { RESEARCH_DB_REF } from 'interface/database'
import { AiMessage } from 'store/slices/ai/ai'
import { callSentryWithFirebaseError } from 'utils/sentry/call-sentry-with-firebase-error'

export const updateAskAiChatLog = async ({
  threadStartTimestamp,
  uid,
  chat,
}: {
  threadStartTimestamp: string
  uid: string
  chat: AiMessage[]
}) => {
  const fireDb = await import('firebase/database')

  const { getDatabase, ref, update } = fireDb

  update(ref(getDatabase()), {
    [getAiChatLogRef(uid, { chatLogsDbRef: RESEARCH_DB_REF.aiChatLogs, threadStartTimestamp })]: chat,
  }).catch(callSentryWithFirebaseError)
}
