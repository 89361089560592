import { OUT_OF_SCREEN_TEXT_OFFSET } from 'constants/pg'

import { GetChunksArgs, GetChunksReturn } from '../interface'
import { getActiveWordEndIndex } from './getActiveWordEndIndex'
import { getActiveWordStartIndex } from './getActiveWordStartIndex'
import { getWords } from './getWords'

export const getChunks = (args: GetChunksArgs): GetChunksReturn => {
  const { text, activeIndex } = args

  const visiblePartEndIndex = Math.min(activeIndex + OUT_OF_SCREEN_TEXT_OFFSET, text.length - 1)

  const hiddenLastPart = text.slice(visiblePartEndIndex + 1, text.length - 1)

  const activeWordStartIndex = getActiveWordStartIndex(text, activeIndex)
  const activeWordEndIndex = getActiveWordEndIndex(text, activeIndex)

  const indexOfFirstCharacterOfLastChunk = activeWordEndIndex + 1

  const firstChunk = text.slice(0, activeWordStartIndex)

  const activeWord = text.slice(activeWordStartIndex, indexOfFirstCharacterOfLastChunk)

  const lastChunk = text.slice(indexOfFirstCharacterOfLastChunk, visiblePartEndIndex + 1)

  const firstChunkWords = getWords(0, firstChunk)

  const lastChunkWords = getWords(indexOfFirstCharacterOfLastChunk, lastChunk)

  const activeIndexWithinActiveChunk = activeIndex - firstChunk.length

  const chunks = {
    firstChunk,
    firstChunkWords,
    active: activeWord,
    activeWordStartIndex,
    lastChunk,
    lastChunkWords,
    hiddenLast: hiddenLastPart,
    activeIndexWithinActiveChunk,
  }

  // console.log({ chunks })

  return chunks
}
