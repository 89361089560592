import '../scripts/wdyr'
import 'styles/globals.css'
// initialize firebase
import 'utils/database/get-initialized-firebase'

import { AppThemeProvider } from 'components/app-theme-provider'
import { ErrorBoundary } from 'components/error-boundary'
import { IS_CYPRESS, IS_PRODUCTION } from 'constants/app'
import { DeviceProvider } from 'context-providers/divice-provider'
import { OfflineProvider } from 'context-providers/offline-provider'
import { PersistedStateProvider } from 'context-providers/persisted-state'
import { scheduledCleanup } from 'local-store/copy'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import { DefaultSeo } from 'next-seo'
import { ThemeProvider as PaletteModeProvider } from 'next-themes'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { Provider as ReduxProvider } from 'react-redux'
import store from 'store'
import initAuth from 'utils/database/init-auth'

import { DEFAULT_SEO_CONFIG } from '../config/next-seo'
import * as gtag from '../lib/gtag'

initAuth()

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(scheduledCleanup, [])

  return (
    <>
      <DefaultSeo
        {...DEFAULT_SEO_CONFIG}
        additionalLinkTags={[
          { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
          { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
          { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
          { rel: 'manifest', href: '/site.webmanifest' },
          { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#00aba9' },
        ]}
        additionalMetaTags={[
          { name: 'msapplication-TileColor', content: '#00aba9' },
          { name: 'theme-color', content: '#ffffff' },
        ]}
      />

      {IS_PRODUCTION && !IS_CYPRESS && (
        <>
          {/* Google Tag Manager */}
          <Script
            id="gtm-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${gtag.GTM_ID}');
          `,
            }}
          />

          {/* Google AdSense */}
          <Script
            async
            id="ad-sense-init"
            strategy="afterInteractive"
            crossOrigin="anonymous"
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2438904058687691"
          />
        </>
      )}

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${gtag.GTM_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      {/* start dummy dom element (TO DEL) */}
      <div id="dummy-dom-element-23-07-02-1416"></div>
      {/* end dummy dom element (TO DEL) */}

      <ErrorBoundary>
        <Toaster
          position="top-right"
          toastOptions={{
            style: {
              boxShadow: '0 0 3px #0005',
              zIndex: 1501,
            },
          }}
        />

        <OfflineProvider>
          <PersistedStateProvider>
            <DeviceProvider>
              <PaletteModeProvider>
                <AppThemeProvider>
                  <ReduxProvider store={store}>
                    <Component {...pageProps} />
                  </ReduxProvider>
                </AppThemeProvider>
              </PaletteModeProvider>
            </DeviceProvider>
          </PersistedStateProvider>
        </OfflineProvider>
      </ErrorBoundary>
    </>
  )
}

export default App
