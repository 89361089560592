import { API } from 'constants/api-routes'
import { AskAiOptions } from 'pages/api/ask-ai/utils/ask-ai'

export const requestAskAi = async (aiOptions: AskAiOptions): Promise<string> => {
  try {
    const response = await fetch(`${API.ASK_AI}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ aiOptions }),
    })

    const data = (await response.json()) as { aiResponseMessage: string }

    return data.aiResponseMessage
  } catch (error) {
    console.error(error)

    throw new Error(`Ai request saga failed.`)
  }
}

export {}
