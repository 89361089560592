import { PUNCTUATIONS } from '../constants'

export const getActiveWordStartIndex = (text: string, activeIndex: number) => {
  if (PUNCTUATIONS.includes(text[activeIndex])) return activeIndex

  let activeWordStartIndex = activeIndex
  while (text[activeWordStartIndex] !== undefined && !PUNCTUATIONS.includes(text[activeWordStartIndex])) {
    activeWordStartIndex--
  }
  return Math.max(0, activeWordStartIndex + 1)
}
