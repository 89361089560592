import { PUNCTUATIONS } from '../constants'
import { GetWordsReturn } from '../interface'

const replacer = '®®®®®'

export const PUNCTUATIONS_MAPPER: Record<string, string> = {
  ',': '(,)',
  '.': '(\\.)',
  '?': '(\\?)',
  ' ': '( )',
}

const PUNCTUATIONS_FOR_REGEX = PUNCTUATIONS.map((punctuation) => PUNCTUATIONS_MAPPER[punctuation])

export const getWords = (indexOffset: number, chunk: string): GetWordsReturn => {
  if (typeof chunk !== 'string') {
    throw new Error('Chunk is not string. It must be at least an empty string')
  }

  if (chunk === '') return []

  // console.time('getWords')

  let updatedChunk = chunk

  PUNCTUATIONS_FOR_REGEX.forEach((punctuation) => {
    const regExp = new RegExp(punctuation, 'g')
    updatedChunk = updatedChunk.replace(regExp, `${replacer}$1${replacer}`)
  })

  const arr = updatedChunk.split(new RegExp(`${replacer}+`, 'g'))

  // remove empty '' array element from the edge because split() returns that unnecessary ''. e.g. 'Hi.'.split('.') => ['Hi', '']
  if (arr[arr.length - 1] === '') arr.pop()
  if (arr[0] === '') arr.shift()

  const finalArr = []
  let totalIndex = indexOffset

  for (let i = 0; i < arr.length; i++) {
    const elem = arr[i]
    const length = elem.length

    const wordObj = {
      word: elem,
      indexOfWordsFirstCharacter: totalIndex,
    }

    finalArr.push(wordObj)

    totalIndex += length
  }

  if (finalArr.length < 1) throw new Error('Words array should not be empty where chunk is not empty.')

  // console.timeEnd('getWords')

  return finalArr
}
