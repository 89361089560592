import { getUserRef } from 'config/database/refs'
import { AiSubscription, DB_REF } from 'interface/database'
import { callSentryWithFirebaseError } from 'utils/sentry/call-sentry-with-firebase-error'

export const updateAiSubscription = async ({
  uid,
  aiSubscription,
}: {
  aiSubscription: AiSubscription
  uid: string
}) => {
  const fireDb = await import('firebase/database')

  const { getDatabase, ref, update } = fireDb

  update(ref(getDatabase()), {
    [getUserRef(uid, { dbRef: DB_REF.aiSubscription })]: aiSubscription,
  }).catch(callSentryWithFirebaseError)
}
