import { getUserRef } from 'config/database/refs'
import { DB_REF } from 'interface/database'

export const updateSetting = async (uid: string, nestedRef: DB_REF, payload: unknown) => {
  const fireDb = await import('firebase/database')
  const { getDatabase, ref, update } = fireDb

  const updated = await update(ref(getDatabase()), {
    [getUserRef(uid, { dbRef: nestedRef })]: payload,
  })

  return updated
}
