import { CSSProperties } from 'react'
import { CSSObject, FlattenSimpleInterpolation } from 'styled-components'

export enum PaletteMode {
  'light' = 'light',
  'dark' = 'dark',
}

export type TogglePaletteMode = (mode: PaletteMode, uid?: string | null) => void

export enum TypographyVariants {
  'heading1' = 'heading1',
  'body1' = 'body1',
  'smallText' = 'smallText',
  'button' = 'button',
}

export enum Breakpoints {
  'xs' = 'xs',
  'sm' = 'sm',
  'md' = 'md',
  'lg' = 'lg',
  'xl' = 'xl',
}

type Breakpoint = keyof typeof Breakpoints

export type BreakpointQueries = Record<keyof typeof Breakpoints, string>

export interface BreakpointSpecifications {
  keys: Breakpoint[]
  values: {
    xs: number
    sm: number
    md: number
    lg: number
    xl: number
  }
  down: BreakpointQueries
  up: BreakpointQueries
}

export interface Palette {
  mode: PaletteMode
  background: {
    default: CSSProperties['backgroundColor']
    paper: CSSProperties['backgroundColor']
  }
  text: {
    primary: CSSProperties['color']
    secondary: CSSProperties['color']
    selection: CSSProperties['backgroundColor']
    error: CSSProperties['color']
  }
  primary: {
    main: CSSProperties['backgroundColor']
    contrastText: CSSProperties['backgroundColor']
    light: CSSProperties['backgroundColor']
  }
  success: {
    main: CSSProperties['backgroundColor']
    light: CSSProperties['backgroundColor']
    contrastText: CSSProperties['backgroundColor']
  }
  error: {
    main: CSSProperties['backgroundColor']
    light: CSSProperties['backgroundColor']
    contrastText: CSSProperties['backgroundColor']
    errorPageColor: CSSProperties['color']
  }
  border: {
    main: CSSProperties['backgroundColor']
    light: CSSProperties['backgroundColor']
  }
  shadow: {
    main: CSSProperties['boxShadow']
  }
  disabled: {
    main: CSSProperties['backgroundColor']
    contrastText: CSSProperties['color']
    border: CSSProperties['backgroundColor']
  }
  link: {
    main: CSSProperties['color']
  }
}

export interface DefaultAppTheme {
  typography: {
    fontSize: CSSProperties['fontSize']
    fontFamily: CSSProperties['fontFamily']
    fontWeightRegular: CSSProperties['fontWeight']
    fontWeightBold: CSSProperties['fontWeight']
    fontWeightBlack: CSSProperties['fontWeight']
    heading1: CSSObject
    heading2: CSSObject
    heading3: CSSObject
    heading4: CSSObject
    body1: CSSObject
    body2: CSSObject
    smallText: CSSObject
    smallText2: CSSObject
    button: CSSObject
  }
  spacing: (..._args: number[]) => FlattenSimpleInterpolation
  breakpoints: BreakpointSpecifications

  borderRadius: {
    0: CSSProperties['borderRadius']
    2: CSSProperties['borderRadius']
    3: CSSProperties['borderRadius']
    5: CSSProperties['borderRadius']
    rounded: CSSProperties['borderRadius']
  }
  zIndex: {
    mobileStepper: number
    appBar: number
    drawer: number
    modal: number
    snackbar: number
    tooltip: number
  }
}

declare module 'styled-components' {
  export interface DefaultTheme extends DefaultAppTheme {
    // palette is the only property that is different between the themes like dark, light
    palette: Palette
    togglePalette: TogglePaletteMode
  }
}
