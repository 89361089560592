import { Stroke } from 'interface/pg'

import { PgProps } from '../pgSlice'

export const addStrokeSlot = ({
  indexToBeAdded,
  now,
  state,
}: {
  indexToBeAdded: number
  now: number
  state: PgProps
}) => {
  const prevAttempts = state.strokes?.[indexToBeAdded]?.attempts || []

  const newInputSlot: Stroke = {
    startTimestamp: now,
    characterToBeTyped: state.text[indexToBeAdded],
    attempts: prevAttempts,
  }

  state.strokes[indexToBeAdded] = newInputSlot
}
