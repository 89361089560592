import { getUserRef } from 'config/database/refs'
import { DB_REF, DbLessonStat, WeaknessStat } from 'interface/database'
import { assertProd } from 'utils/assert-prod'
import { getInitializedFirebaseApp } from 'utils/database/get-initialized-firebase'
import { callSentryWithFirebaseError } from 'utils/sentry/call-sentry-with-firebase-error'

const MAX_ENTRIES = 30

interface UpdateStats {
  id: string
  newEntryLessonStats: DbLessonStat

  newEntryUppercase: WeaknessStat
  newEntryLowercase: WeaknessStat
  newEntryNum: WeaknessStat
  newEntryPunctuation: WeaknessStat
  newEntryNgBi: WeaknessStat
  newEntryNgTri: WeaknessStat
  newEntryNg4: WeaknessStat
  newEntryCw1_50: WeaknessStat
  newEntryCw51_100: WeaknessStat
  newEntryCw101_150: WeaknessStat
  newEntryCw151_200: WeaknessStat
  newEntryCw201_250: WeaknessStat
  newEntryCw251_300: WeaknessStat
  newEntryCw301_400: WeaknessStat
  newEntryCw401_500: WeaknessStat
  newEntryCw501_600: WeaknessStat
  newEntryCw601_700: WeaknessStat
  newEntryCw701_800: WeaknessStat
  newEntryCw801_900: WeaknessStat
  newEntryCw901_1000: WeaknessStat
}

export const updateStats = async (args: UpdateStats) => {
  const {
    id,
    newEntryLessonStats,
    newEntryUppercase,
    newEntryLowercase,
    newEntryNum,
    newEntryPunctuation,
    newEntryNgBi,
    newEntryNgTri,
    newEntryNg4,
    newEntryCw1_50,
    newEntryCw51_100,
    newEntryCw101_150,
    newEntryCw151_200,
    newEntryCw201_250,
    newEntryCw251_300,
    newEntryCw301_400,
    newEntryCw401_500,
    newEntryCw501_600,
    newEntryCw601_700,
    newEntryCw701_800,
    newEntryCw801_900,
    newEntryCw901_1000,
  } = args

  assertProd(!!id, 'the weakness stat id is not defined.')
  assertProd(!!newEntryLessonStats, 'the weakness stat stat is not defined.')
  assertProd(!!newEntryUppercase, 'the weakness stat UPPERCASE is not defined.')
  assertProd(!!newEntryLowercase, 'the weakness stat LOWERCASE is not defined.')
  assertProd(!!newEntryNum, 'the weakness stat NUM is not defined.')
  assertProd(!!newEntryPunctuation, 'the weakness stat PUNCTUATION is not defined.')
  assertProd(!!newEntryNgBi, 'the weakness stat NG_BI is not defined.')
  assertProd(!!newEntryNgTri, 'the weakness stat NG_TRI is not defined.')
  assertProd(!!newEntryNg4, 'the weakness stat NG_4 is not defined.')
  assertProd(!!newEntryCw1_50, 'the weakness stat CW_1_50 is not defined.')
  assertProd(!!newEntryCw51_100, 'the weakness stat CW_51_100 is not defined.')
  assertProd(!!newEntryCw101_150, 'the weakness stat CW_101_150 is not defined.')
  assertProd(!!newEntryCw151_200, 'the weakness stat CW_151_200 is not defined.')
  assertProd(!!newEntryCw201_250, 'the weakness stat CW_201_250 is not defined.')
  assertProd(!!newEntryCw251_300, 'the weakness stat CW_251_300 is not defined.')
  assertProd(!!newEntryCw301_400, 'the weakness stat CW_301_400 is not defined.')
  assertProd(!!newEntryCw401_500, 'the weakness stat CW_401_500 is not defined.')
  assertProd(!!newEntryCw501_600, 'the weakness stat CW_501_600 is not defined.')
  assertProd(!!newEntryCw601_700, 'the weakness stat CW_601_700 is not defined.')
  assertProd(!!newEntryCw701_800, 'the weakness stat CW_701_800 is not defined.')
  assertProd(!!newEntryCw801_900, 'the weakness stat CW_801_900 is not defined.')
  assertProd(!!newEntryCw901_1000, 'the weakness stat CW_901_1000 is not defined.')

  const fireDb = await import('firebase/database')

  const { getDatabase, ref, push, set, update, get } = fireDb

  const db = getDatabase(getInitializedFirebaseApp())

  // update lessonStats
  const listRef = ref(db, getUserRef(id, { dbRef: DB_REF.stats }))
  const newRef = push(listRef)
  set(newRef, newEntryLessonStats).catch(callSentryWithFirebaseError)

  const updateWeaknessStat = async ({ dbRef, weaknessItem }: { dbRef: DB_REF; weaknessItem: WeaknessStat }) => {
    // Iterate through the keys of weaknessItem (an object with weaknessItem categories as keys, ie: ,./ etc in case of punctuations)
    Object.keys(weaknessItem).forEach((key /* ae, ou, etc in case of bi-grams */) => {
      // Retrieve the array of new wpm for the current weaknessItem category (key)
      const newEntry = weaknessItem[key]

      // Generate the user-specific reference path for the current weaknessItem category
      const weaknessItemUserRef = getUserRef(id, { dbRef, firstNestedRef: key })

      // Create a reference to the user's weaknessItem data for the current category
      const weaknessItemRef = ref(db, weaknessItemUserRef)

      // Retrieve the existing weaknessItem data for the current category from the Firebase Realtime Database
      get(weaknessItemRef).then((snapshot) => {
        // Get the current weaknessItem stats from the snapshot; if it doesn't exist, use an empty object
        const currentStats = snapshot.val() || {}

        // Convert the current weaknessItem stats (stored as an object) to an array of numbers
        const currentStatsArray: number[] = Object.values(currentStats)

        // Concatenate the newEntry array with the currentStatsArray and keep only the last 30 numbers
        const updatedStatsArray: number[] = [...currentStatsArray, ...newEntry].slice(-MAX_ENTRIES)

        // Convert the updatedStatsArray back to an object, using unique keys generated by the push() method
        const updatedStats = updatedStatsArray.reduce((acc: Record<string, number>, val) => {
          // Generate a unique key for the current value using the push() method
          const newKey = push(weaknessItemRef).key

          // If a unique key was successfully generated, add the value to the accumulator object using the unique key
          if (newKey) {
            acc[newKey] = val
          }

          // Return the accumulator object to be used in the next iteration
          return acc
        }, {})

        // Update the user's weaknessItem stats for the current category (ie: ae, ou in case of bi-grams) with the new object containing unique keys
        update(ref(db), {
          [weaknessItemUserRef]: updatedStats,
        }).catch(callSentryWithFirebaseError) // Handle errors using the callSentryWithFirebaseError function
      })
    })
  }

  // update weakness stats
  await updateWeaknessStat({ dbRef: DB_REF.uppercase, weaknessItem: newEntryUppercase })
  await updateWeaknessStat({ dbRef: DB_REF.lowercase, weaknessItem: newEntryLowercase })
  await updateWeaknessStat({ dbRef: DB_REF.num, weaknessItem: newEntryNum })
  await updateWeaknessStat({ dbRef: DB_REF.punctuation, weaknessItem: newEntryPunctuation })
  await updateWeaknessStat({ dbRef: DB_REF.ngBi, weaknessItem: newEntryNgBi })
  await updateWeaknessStat({ dbRef: DB_REF.ngTri, weaknessItem: newEntryNgTri })
  await updateWeaknessStat({ dbRef: DB_REF.ng4, weaknessItem: newEntryNg4 })
  await updateWeaknessStat({ dbRef: DB_REF.cw1_50, weaknessItem: newEntryCw1_50 })
  await updateWeaknessStat({ dbRef: DB_REF.cw51_100, weaknessItem: newEntryCw51_100 })
  await updateWeaknessStat({ dbRef: DB_REF.cw101_150, weaknessItem: newEntryCw101_150 })
  await updateWeaknessStat({ dbRef: DB_REF.cw151_200, weaknessItem: newEntryCw151_200 })
  await updateWeaknessStat({ dbRef: DB_REF.cw201_250, weaknessItem: newEntryCw201_250 })
  await updateWeaknessStat({ dbRef: DB_REF.cw251_300, weaknessItem: newEntryCw251_300 })
  await updateWeaknessStat({ dbRef: DB_REF.cw301_400, weaknessItem: newEntryCw301_400 })
  await updateWeaknessStat({ dbRef: DB_REF.cw401_500, weaknessItem: newEntryCw401_500 })
  await updateWeaknessStat({ dbRef: DB_REF.cw501_600, weaknessItem: newEntryCw501_600 })
  await updateWeaknessStat({ dbRef: DB_REF.cw601_700, weaknessItem: newEntryCw601_700 })
  await updateWeaknessStat({ dbRef: DB_REF.cw701_800, weaknessItem: newEntryCw701_800 })
  await updateWeaknessStat({ dbRef: DB_REF.cw801_900, weaknessItem: newEntryCw801_900 })
  await updateWeaknessStat({ dbRef: DB_REF.cw901_1000, weaknessItem: newEntryCw901_1000 })
}
