import { getAiBetaSubscribersRef } from 'config/database/refs'
import { assertProd } from 'utils/assert-prod'
import { callSentryWithFirebaseError } from 'utils/sentry/call-sentry-with-firebase-error'

export const addAiBetaSubscriber = async ({ uid }: { uid: string }) => {
  assertProd(!!uid, 'uid is required (addAiBetaSubscriber)')

  const fireDb = await import('firebase/database')

  const { getDatabase, ref, set } = fireDb

  const targetRef = getAiBetaSubscribersRef()

  // Use the uid as the key in the database
  const userRef = ref(getDatabase(), `${targetRef}/${uid}`)

  // Set the uid as the value at the specified reference
  set(userRef, uid).catch(callSentryWithFirebaseError)
}
