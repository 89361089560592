import { createContext, ReactNode, useContext } from 'react'
import { isTouchDevice } from 'utils/is-touch-device'

const DeviceContext = createContext({ isTouchDevice: true } as { isTouchDevice: boolean })

export const DeviceProvider = ({ children }: { children: ReactNode }) => {
  return <DeviceContext.Provider value={{ isTouchDevice: isTouchDevice() }}>{children}</DeviceContext.Provider>
}

export const useDevice = () => useContext(DeviceContext)
