import { SagaMiddleware } from '@redux-saga/core'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { PG } from 'constants/pg'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { watcherSaga } from 'store/sagas/root-saga'
import { pgSlices } from 'store/slices/pg-slice'
import { settingSlice } from 'store/slices/setting'
import { statSlice } from 'store/slices/stat'

import { aiSlice } from './slices/ai/ai'
import { aiBetaSubscribersSlice } from './slices/ai-beta-subscribers/ai-beta-subscribers'
import { subscriptionSlice } from './slices/subscription/subscription'

// HIGHLIGHT: correctly type the sagaMiddleware, try to make it not like `SagaMiddleware<object>`, not sure SagaMiddleware<UserData> works.
const sagaMiddleware: SagaMiddleware<Record<string, unknown>> = createSagaMiddleware()

const rootReducer = combineReducers({
  setting: settingSlice.reducer,
  stat: statSlice.reducer,
  ai: aiSlice.reducer,
  subscription: subscriptionSlice.reducer,
  aiBetaSubscribers: aiBetaSubscribersSlice.reducer,
  [PG.MAIN_PG]: pgSlices[PG.MAIN_PG].reducer,
  [PG.NUMBER_PRACTICE_PG]: pgSlices[PG.NUMBER_PRACTICE_PG].reducer,

  // user: userReducer.reducer,
})

const store = configureStore({
  reducer: rootReducer,

  // https://redux-toolkit.js.org/usage/usage-with-typescript#correct-typings-for-the-dispatch-type
  // above doc suggests using the .concat(...) and .prepend(...) methods of the MiddlewareArray returned by getDefaultMiddleware() instead of [...spread] for Typescript
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false })
      // prepend and concat calls can be chained
      .concat(
        sagaMiddleware
        // , logger
      ),
})

sagaMiddleware.run(watcherSaga)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
