// credit: https://stackoverflow.com/a/51398471/7562739

import { MAX_MESSAGE_LENGTH_API_REQUEST } from 'constants/ai'
import { AskAiUserLevels } from 'pages/api/ask-ai/utils/ask-ai'
import { call, put, select } from 'redux-saga/effects'
import { requestAskAi } from 'store/sagas/requests/ai/ask-ai'
import {
  AiMessage,
  AskAiPayload,
  selectAiMessages,
  selectThreadStartTimestamp,
  setAiMessageError,
  setAiMessages,
} from 'store/slices/ai/ai'
import {
  selectIsEvaluationUnderway,
  selectIsUserBrandNew,
  selectRecentWpm,
  selectWeaknessProfile,
} from 'store/slices/stat/stat'
import { updateAskAiChatLoad } from 'utils/database/update-ask-ai-chat-load'
import { updateAskAiChatLog } from 'utils/database/update-ask-ai-chat-logs'

import { getCurtailedMessages } from './utils/get-curtailed-messages'

// for put effect PutEffect<PayloadAction<CopyResponse>> can be used and import be like ---> import { AnyAction } from 'redux';
type WhatYouYield = any
type WhatYouReturn = any

type WhatYouAcceptFromAI = any

export function* handleAskAi(action: {
  payload: AskAiPayload
}): Generator<WhatYouYield, WhatYouReturn, WhatYouAcceptFromAI> {
  // previousMessages should contain all the message from the user and the ai
  const previousMessages = (yield select(selectAiMessages)) as AiMessage[]

  // threadStartTimestamp is the timestamp of the first message in the thread
  const threadStartTimestamp = (yield select(selectThreadStartTimestamp)) as number

  // Curtail down to 2000 characters to send it for the openAI API
  const previousMessagesCurtailed = getCurtailedMessages(
    previousMessages as AiMessage[],
    MAX_MESSAGE_LENGTH_API_REQUEST
  )

  try {
    const { uid, userMessage } = action.payload

    const isEvaluationUnderway = yield select(selectIsEvaluationUnderway)
    const isUserBrandNew = yield select(selectIsUserBrandNew)
    const weaknessProfile = yield select(selectWeaknessProfile)
    const wpm = yield select(selectRecentWpm)

    console.log('???', wpm)

    const aiResponseMessage = (yield call(requestAskAi, {
      messages: previousMessagesCurtailed,
      userLevel: isUserBrandNew
        ? AskAiUserLevels.newUser
        : isEvaluationUnderway
        ? AskAiUserLevels.evaluationInProgress
        : AskAiUserLevels.oldUser,
      weaknessProfile: weaknessProfile,
      wpm,
    })) as string

    yield put(
      setAiMessages(
        aiResponseMessage
          ? aiResponseMessage
          : 'No message found. Please try again later. If the problem persists, please contact support@typingmentor.com.'
      )
    )

    // update the chat load of the user. Note we store chat length against the current timestamp
    yield call(updateAskAiChatLoad, { uid, chat: { [Date.now()]: { length: userMessage.length } } })

    // update chat history for research purpose
    yield call(updateAskAiChatLog, {
      chat: [...previousMessages, { role: 'assistant', content: aiResponseMessage }],
      uid,
      threadStartTimestamp: threadStartTimestamp.toString(),
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)

    yield put(setAiMessageError("Couldn't fetch message from AI"))
  }
}

export {}
