export type Duos =
  | 'he'
  | 'th'
  | 'in'
  | 'er'
  | 'an'
  | 're'
  | 'on'
  | 'ed'
  | 'nd'
  | 'at'
  | 'en'
  | 'ha'
  | 'ou'
  | 'ng'
  | 'to'
  | 'or'
  | 'es'
  | 'ar'
  | 'st'
  | 'is'
  | 'it'
  | 'te'
  | 'ti'
  | 'al'
  | 'as'
  | 'hi'
  | 'of'
  | 'nt'
  | 'le'
  | 've'

// this ensures that we have all the items in the type and the object
const duosObj: Record<Duos, Duos> = {
  he: 'he',
  th: 'th',
  in: 'in',
  er: 'er',
  an: 'an',
  re: 're',
  on: 'on',
  ed: 'ed',
  nd: 'nd',
  at: 'at',
  en: 'en',
  ha: 'ha',
  ou: 'ou',
  ng: 'ng',
  to: 'to',
  or: 'or',
  es: 'es',
  ar: 'ar',
  st: 'st',
  is: 'is',
  it: 'it',
  te: 'te',
  ti: 'ti',
  al: 'al',
  as: 'as',
  hi: 'hi',
  of: 'of',
  nt: 'nt',
  le: 'le',
  ve: 've',
}

export const duos: Duos[] = Object.keys(duosObj) as Duos[]

export type Trios =
  | 'the'
  | 'ing'
  | 'and'
  | 'her'
  | 'ion'
  | 'ent'
  | 'hat'
  | 'tio'
  | 'tha'
  | 'for'
  | 'ter'
  | 'was'
  | 'his'
  | 'ati'
  | 'ver'
  | 'The'
  | 'ith'
  | 'thi'
  | 'ate'
  | 'not'
  | 'ers'
  | 'wit'
  | 'ght'
  | 'she'
  | 'ted'
  | 'nce'
  | 'rea'
  | 'men'
  | 'con'
  | 'ons'

// this ensures that we have all the items in the type and the object
const triosObj: Record<Trios, Trios> = {
  the: 'the',
  ing: 'ing',
  and: 'and',
  her: 'her',
  ion: 'ion',
  ent: 'ent',
  hat: 'hat',
  tio: 'tio',
  tha: 'tha',
  for: 'for',
  ter: 'ter',
  was: 'was',
  his: 'his',
  ati: 'ati',
  ver: 'ver',
  The: 'The',
  ith: 'ith',
  thi: 'thi',
  ate: 'ate',
  not: 'not',
  ers: 'ers',
  wit: 'wit',
  ght: 'ght',
  she: 'she',
  ted: 'ted',
  nce: 'nce',
  rea: 'rea',
  men: 'men',
  con: 'con',
  ons: 'ons',
}

export const trios: Trios[] = Object.keys(triosObj) as Trios[]

export type Quads =
  | 'tion'
  | 'ther'
  | 'atio'
  | 'ould'
  | 'ight'
  | 'ment'
  | 'thin'
  | 'ough'
  | 'ting'
  | 'hing'
  | 'ding'
  | 'ound'
  | 'some'
  | 'over'
  | 'thou'
  | 'inte'

// this ensures that we have all the items in the type and the object
const quadsObj: Record<Quads, Quads> = {
  tion: 'tion',
  ther: 'ther',
  atio: 'atio',
  ould: 'ould',
  ight: 'ight',
  ment: 'ment',
  thin: 'thin',
  ough: 'ough',
  ting: 'ting',
  hing: 'hing',
  ding: 'ding',
  ound: 'ound',
  some: 'some',
  over: 'over',
  thou: 'thou',
  inte: 'inte',
}

export const quads: Quads[] = Object.keys(quadsObj) as Quads[]
