import { generateWords } from 'utils/lessons/generate-words'

export const punctuationChars = [
  '.',
  ',',
  "'",
  '!',
  '-',
  ';',
  '$',
  '@',
  ':',
  '?',
  '#',
  '%',
  '*',
  '(',
  ')',
  '`',
  '~',
  '^',
  '&',
  '_',
  '=',
  '+',
  '[',
  '{',
  ']',
  '}',
  '"',
  '\\',
  '|',
  '<',
  '>',
  '/',
]

export const punctuations = generateWords(punctuationChars, 50)
