import { isClient } from 'utils/is-client'

export const isTouchDevice = () => {
  if (isClient() && window.matchMedia('(pointer: coarse)').matches) {
    return true
  }

  return false

  // return Boolean(
  //   'ontouchstart' in window ||
  //     navigator.maxTouchPoints > 0 ||
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     (navigator as any).msMaxTouchPoints > 0
  // )
}
