import { IS_DEV } from 'constants/app'

export const SITE_URL_ORIGIN = 'https://typingmentor.com/'
export const LOCAL_DEV_URL_ORIGIN = 'http://localhost:3000/'
export const ENV_BASED_URL_ORIGIN = IS_DEV ? LOCAL_DEV_URL_ORIGIN : SITE_URL_ORIGIN

export const SITE_METADATA = {
  siteUrl: SITE_URL_ORIGIN,
  defaultTitle: `Online Typing Practice`,
  defaultDescription: `Take free online typing test to get highly customized lesson plan and infographic typing insights of typing practice. Get your maximum wpm and accuracy!`,
  name: `TypingMentor`,
}

export const OLD_SITE = 'https://62426a2690947500094fe28e--typingmentor.netlify.app/'
