import { PaletteMode } from 'components/app-theme-provider/interface'
import { getUserRef } from 'config/database/refs'
import { DB_REF } from 'interface/database'
import { callSentryWithFirebaseError } from 'utils/sentry/call-sentry-with-firebase-error'

export const updatePalette = async (uid: string, paletteMode: PaletteMode) => {
  const fireDb = await import('firebase/database')

  const { getDatabase, ref, update } = fireDb

  update(ref(getDatabase()), {
    [getUserRef(uid, { dbRef: DB_REF.paletteMode })]: paletteMode,
  }).catch(callSentryWithFirebaseError)
}
