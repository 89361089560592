import { AI_GUIDED_LESSON_BUTTON_TEXT, EVALUATION_BUTTON_TEXT } from 'constants/ai'
import { WeaknessProfile } from 'constants/stats'
import { AiMessage } from 'store/slices/ai/ai'
import { assert } from 'utils/assert'

const ASK_AI = {
  commonEnd: `Remember you should skillfully avoid any discussion unrelated to touch typing`, // this is a common part of the message

  // this part is common regardless of the user is new or not
  commonStart: `You are an AI-powered touch-typing tutor and an expert at TypingMentor. You skillfully avoid any discussion unrelated to touch typing. Your replies are brief, concise, and to the point. You are aware that TypingMentor offers a wide variety of lessons. With its AI feature, TypingMentor generates typing exercises targeting the user's weakest characters, bigrams, trigrams, and words. A button called "${AI_GUIDED_LESSON_BUTTON_TEXT}" is available below for generating these personalized lessons. User's progress is tracked at https://typingmentor.com/stats/`,

  // this part is for brand the new user
  newUser: `This is a new user on typingmentor.com. So, greet them properly in a friendly tone. Also, inform them that in order to comprehend their typing abilities, it's essential for us to evaluate them initially. Encourage them to click the button called "${EVALUATION_BUTTON_TEXT}" below, which will initiate a series of tests for this new user, assessing their strengths and areas for improvement in typing.`,

  // this part is for new user who has already started the evaluation
  onboarding: `This is a new user on typingmentor.com therefore it is vital for us to first assess their typing abilities in order to provide tailored support. Inform them that their typing skills evaluation is currently underway. Urge them to click the "${EVALUATION_BUTTON_TEXT}" button below, which will continue the sequence of tests for this new user, identifying both their strengths and areas that need enhancement in typing.`,
}

export enum AskAiUserLevels {
  'newUser' = 'newUser',
  'evaluationInProgress' = 'evaluationInProgress',
  'oldUser' = 'oldUser',
}

export type AskAiOptions = {
  messages: AiMessage[]
  userLevel: AskAiUserLevels
  weaknessProfile: WeaknessProfile
  wpm: number
}

export const askAi = ({ userLevel, weaknessProfile, wpm }: Omit<AskAiOptions, 'messages'>): string => {
  switch (userLevel) {
    case AskAiUserLevels.newUser: {
      return `${ASK_AI.commonStart} ${ASK_AI.newUser} ${ASK_AI.commonEnd}}`
    }

    case AskAiUserLevels.evaluationInProgress: {
      return `${ASK_AI.commonStart} ${ASK_AI.onboarding} ${ASK_AI.commonEnd}`
    }

    case AskAiUserLevels.oldUser: {
      const { words, ngrams, characters } = weaknessProfile

      const charactersText = characters.map((el) => `"${el}"`).join(', ')
      const ngramsText = ngrams.map((el) => `"${el}"`).join(', ')
      const wordsText = words.map((el) => `"${el}"`).join(', ')

      return `${ASK_AI.commonStart} This user's weakest characters are ${charactersText}, n-grams (sequence of characters) are ${ngramsText}, and words are ${wordsText}. TypingMentor measured this user's recent typing speed as ${wpm} WPM. ${ASK_AI.commonEnd}`
    }

    default: {
      assert(false, 'Should not reach here on askAi')
      return ''
    }
  }
}
