import { getRandomUniqueNumbers } from 'utils/get-random-unique-numbers'

/**
 *
 * @param chars characters that can be used for creating the words
 * @param size number words to be generate
 * @returns
 */
export const generateWords = (chars: string[], size: number) => {
  const found = []
  const MAX_WORD_LENGTH = 4

  while (found.length < size) {
    const chosenIndices = getRandomUniqueNumbers(
      0,
      chars.length - 1,
      // random integer from 1 to MAX_WORD_LENGTH:
      1 + Math.floor(Math.random() * MAX_WORD_LENGTH)
    )

    const createdWord = chosenIndices.map((index) => chars[index]).join('')
    found.push(createdWord)
  }

  return found
}
