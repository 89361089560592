export const white = '#FFFFFF'
export const grayWhitish = '#f6f6f6'

export const black = '#000000'

export const gray3 = '#333333'
export const gray4 = '#424242'
export const grayB = '#b3b3b3'
export const grayC = '#cccccc'
export const lightGray = '#d3d3d3'
export const darkGray = '#222222'

export const turquoise = '#30D5C8'
export const turquoiseLight = '#3aeeD8'
export const licorice = '#2e3644'
export const licoriceLight = '#465361'
export const licoriceLighter = '#596272'
export const licoriceDark = '#191e25'
export const licoriceLessDark = '#20242c'
export const teal = '#00796b'
export const tealLight = '#0a8b7c'

export const green1 = '#4caf50'
export const green2 = '#4caf50aa'
export const green3 = '#4caf5066'

export const red1 = '#f44336'
export const red2 = '#f44336aa'
export const red3 = '#f4433666'

export const gray = '#555'
