import { WeaknessStat } from 'interface/database'
import {
  SingleTopBottomItem,
  TopBottomCategories,
  TopBottomKeysProps,
} from 'views/stats/components/top-bottom-keys/top-bottom-keys'

export type TopBottomCategoriesStats = Partial<Record<TopBottomCategories, WeaknessStat | null>>

/**
 * Calculate the average value of a numeric array
 * @param arr - The array of numbers to calculate the average of
 * @returns - The average value of the array
 */
const calculateAverage = (arr: number[]): number => {
  const sum = arr.reduce((a, b) => a + b, 0)
  return arr.length > 0 ? sum / arr.length : 0
}

/**
 * Calculate the accuracy percentage of a given WPM array
 * @param arr - The array of WPM values, where 0 indicates an incorrect typing
 * @returns - The accuracy percentage as an integer between 0 and 100
 */
const calculateAccuracyPercentage = (arr: number[]): number => {
  const correctEntries = arr.filter((wpm) => wpm !== 0).length
  const accuracy = arr.length > 0 ? (correctEntries / arr.length) * 100 : 0
  return Math.round(accuracy)
}

/**
 * Generate the top and bottom stats for speed and accuracy based on the given WeaknessStat
 * @param weaknessStat - The WeaknessStat object containing the typing speed data
 * @returns - The TopBottomKeysProps object containing top and bottom stats for speed and accuracy
 */
export const getTopBottomKeysProps = (weaknessStat: TopBottomCategoriesStats): TopBottomKeysProps => {
  const topBottomStats: TopBottomKeysProps = {
    speed: { top: [] as SingleTopBottomItem[], bottom: [] as SingleTopBottomItem[] },
    accuracy: { top: [] as SingleTopBottomItem[], bottom: [] as SingleTopBottomItem[] },
  }

  // Iterate through each character type in the WeaknessStat object
  Object.entries(weaknessStat).forEach(([category, stat]) => {
    if (!stat) return

    // Iterate through each character's WPM data
    Object.entries(stat).forEach(([letter, wpmArray]) => {
      const speed = Math.round(calculateAverage(wpmArray))
      const accuracy = calculateAccuracyPercentage(wpmArray)

      const singleStat: SingleTopBottomItem = { statCategory: category, letter, stat: speed }

      // Update the top and bottom stats for speed and accuracy
      topBottomStats.speed.top.push(singleStat)
      topBottomStats.speed.bottom.push(singleStat)

      topBottomStats.accuracy.top.push({ ...singleStat, stat: accuracy })
      topBottomStats.accuracy.bottom.push({ ...singleStat, stat: accuracy })

      // Sort the top and bottom stats and keep only the top/bottom 5
      topBottomStats.speed.top.sort((a, b) => b.stat - a.stat).splice(5)
      topBottomStats.speed.bottom.sort((a, b) => a.stat - b.stat).splice(5)

      topBottomStats.accuracy.top.sort((a, b) => b.stat - a.stat).splice(5)
      topBottomStats.accuracy.bottom.sort((a, b) => a.stat - b.stat).splice(5)
    })
  })

  return topBottomStats
}
