import * as Sentry from '@sentry/nextjs'
import { SentryPageTag } from 'constants/sentry'
import toast from 'react-hot-toast'
import { relativeUrl } from 'utils/relative-url'

// this util calls sentry with a message (not an error).
// this utils shows a toast message
export const callSentry = ({
  pageTag,
  message,
  toastMessage,
  isInfoToast,
}: {
  pageTag: SentryPageTag
  message: string
  toastMessage?: string
  isInfoToast?: boolean
}) => {
  if (toastMessage) toast[isInfoToast ? 'success' : 'error'](toastMessage)

  Sentry.captureMessage(message, {
    tags: {
      page: pageTag,
      url: relativeUrl(window.location.href),
    },
  })
}
