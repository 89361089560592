import createPersistedState from 'hooks/use-persisted-state'
import { createContext, ReactNode, useContext } from 'react'

const useTrainedOnEscForNext = createPersistedState('persisted-trainedOnEscForNext')
const useShowChatGptBetaButton = createPersistedState('persisted-showChatGptBetaButton')

interface PersistedState {
  trainedOnEscForNext: boolean
  setTrainedOnEscForNext: (trainingDone: boolean) => void

  // we hide ChatGpt Beta subscription Button for those users who clicked on cross icon on the button. So we need to persist it.
  showChatGptBetaButton: boolean
  setShowChatGptBetaButton: (shouldBeHidden: boolean) => void
}

const initialState: PersistedState = {
  trainedOnEscForNext: false,
  setTrainedOnEscForNext: () => '',

  showChatGptBetaButton: false,
  setShowChatGptBetaButton: () => '',
}

const PersistedStateContext = createContext(initialState)

/**
 * trainedOnEscForNext and setTrainedOnEscForNext is just created. Not used yet. Use this if you want
 * to use localStorage as well as want the react trainedOnEscForNext to sync up with that.
 * @param param0
 * @returns
 */
export const PersistedStateProvider = ({ children }: { children: ReactNode }) => {
  const [trainedOnEscForNext, setTrainedOnEscForNext] = useTrainedOnEscForNext(false)
  const [showChatGptBetaButton, setShowChatGptBetaButton] = useShowChatGptBetaButton(true)

  const value: PersistedState = {
    trainedOnEscForNext,
    setTrainedOnEscForNext,
    showChatGptBetaButton,
    setShowChatGptBetaButton,
  }

  return <PersistedStateContext.Provider value={value}>{children}</PersistedStateContext.Provider>
}

export const usePersistedState = () => useContext(PersistedStateContext)
