import { createSlice } from '@reduxjs/toolkit'
import { AiSubscription } from 'interface/database'
import { RootState } from 'store'

export type FetchSubscriptionDataPayload = { uid: string; userMessage: string }

export type SubscriptionMessage = { role: 'user' | 'assistant'; content: string }

export type SetAiSubscriptionPayload = { uid: string; type: AiSubscription['type']; expiresAt: number }

// Define a type for the slice State
export type SubscriptionProps = {
  aiSubscriptionType: AiSubscription['type'] | null
  aiSubscriptionExpiry: AiSubscription['expiresAt'] | null
  aiSubscriptionLoading: boolean
  aiSubscriptionLoaded: boolean
  aiSubscriptionError: string | null
}

const initialState: SubscriptionProps = Object.freeze({
  aiSubscriptionType: null,
  aiSubscriptionExpiry: null,
  aiSubscriptionLoading: false,

  // aiSubscriptionLoaded implies we fetched the data from the server and now we know if the user has a subscription or not
  aiSubscriptionLoaded: false,
  aiSubscriptionError: null,
})

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setAiSubscriptionLoading: (state, action: { payload: boolean }) => {
      const { payload } = action
      state.aiSubscriptionLoading = payload
      state.aiSubscriptionLoaded = false
    },
    setAiSubscription: (_state, _action: { payload: SetAiSubscriptionPayload }) => {
      // saga watcher, you should call this with the payload from the App
    },
    __setAiSubscription: (state, action: { payload: AiSubscription }) => {
      state.aiSubscriptionType = action.payload.type
      state.aiSubscriptionExpiry = action.payload.expiresAt
      state.aiSubscriptionLoading = false
      state.aiSubscriptionLoaded = true
      state.aiSubscriptionError = null
    },
    setAiSubscriptionError: (state, action: { payload: string }) => {
      state.aiSubscriptionLoading = false
      state.aiSubscriptionError = action.payload
    },
  },
})

export const { setAiSubscriptionLoading, setAiSubscription, __setAiSubscription, setAiSubscriptionError } =
  subscriptionSlice.actions

export { subscriptionSlice }

export const selectStatSlice = (state: RootState) => state.subscription

export const selectAiSubscriptionType = (state: RootState) => selectStatSlice(state).aiSubscriptionType
export const selectAiSubscriptionExpiry = (state: RootState) => selectStatSlice(state).aiSubscriptionExpiry
export const selectAiSubscriptionLoading = (state: RootState) => selectStatSlice(state).aiSubscriptionLoading
export const selectAiSubscriptionLoaded = (state: RootState) => selectStatSlice(state).aiSubscriptionLoaded
export const selectAiSubscriptionError = (state: RootState) => selectStatSlice(state).aiSubscriptionError

// export const selectIsAiSubscriptionLoaded = (state: RootState) => selectStatSlice(state).aiSubscriptionLoaded

export const selectIsAiSubscriptionExpired = (state: RootState) => {
  const expiry = selectAiSubscriptionExpiry(state)
  if (!expiry) return false
  return expiry < Date.now().valueOf()
}

export const selectIsAiSubscriptionActive = (state: RootState) => {
  const aiSubscriptionType = selectAiSubscriptionType(state)
  const aiSubscriptionExpired = selectIsAiSubscriptionExpired(state)

  return (
    !aiSubscriptionExpired &&
    (aiSubscriptionType === 'beta' || aiSubscriptionType === 'subscriber' || aiSubscriptionType === 'trial')
  )
}
